import { Component, OnInit } from '@angular/core';
import { HeadService } from 'src/app/services/head.service';

@Component({
  selector: 'app-taxpreparation',
  templateUrl: './taxpreparation.component.html',
  styleUrls: ['./taxpreparation.component.scss'],
  standalone:false
})
export class TaxpreparationComponent implements OnInit {

  constructor(private head:HeadService
     ) 
     { 
       this.head.setTitle("Tax Preperation");
       this.head.setKeywords("IFA Taxes, Tax Preperation");
       this.head.setDescription("IFA Tax Returns, Tax Planning, Tax Advice, Bookkeeping & Accounting");
       this.head.setMetaProperty("author","IFA Taxes");
       this.head.setMetaProperty("robots","index, follow"); }

  ngOnInit(): void {
  }

}
