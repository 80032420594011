<head>
    <title>Required Minimum Distribution (RMD)</title>
    <meta name="keywords" content="Required Minimum Distribution Calculator, Required Minimum Distribution, RMD">
    <meta name="description" content="Use this calculator to determine your Required Minimum Distribution (RMD).">
    <meta name="author" content="IFA Taxes">
    <meta name="robots" content="index, follow">
</head>

<app-taxes-top-menu></app-taxes-top-menu>
<app-jumbotron title="Required Minimum Distribution (RMD)"></app-jumbotron>

<div class="container">
    <div class="row">
      <div class="col-12">
        <re-captcha (resolved)="null" style="width:100%; margin:auto; display: none;">
        </re-captcha>
        <span id='KJECalculatorDescID'>

          Use this calculator to determine your Required Minimum Distribution (RMD). The IRS requires that you withdraw at least a minimum amount - known as a Required Minimum Distribution - from some types of retirement accounts annually. The distributions are required to start when you turn age 72 (or 70 1/2 if you were born before 7/1/1949). This calculator has been updated for the 'SECURE Act of 2019 and CARES Act of 2020'.

        </span>
        <!--CALC_DESC_END-->
        <noscript>
          <div class="KJECenterText">
            <div id="KJENoJavaScript" class="KJENoJavaScript">Javascript is required for this calculator. If you are
              using Internet Explorer, you may need to select to 'Allow Blocked Content' to view this calculator.
              <p>For more information about these these financial calculators please visit: <a routerLink="http://www.dinkytown.net">Financial
                  Calculators from KJE Computer Solutions, Inc.</a></p>
            </div>
          </div>
        </noscript>
        <div id="KJEAllContent"></div>

        <br>
        <hr class="KJEFoooterLine">
        <div class="KJECenterText">
          <p><span class='KJEBold'>IFA Taxes, LLC.</span><br>19200 Von Karman Ave Ste 100<br>Irvine, CA</p>
          <p class="KJEFooter">Information and interactive calculators are made available to you as self-help tools for
            your independent use and are not intended to provide investment advice. We cannot and do not guarantee
            their applicability or accuracy in regards to your individual circumstances. All examples are hypothetical
            and are for illustrative purposes. We encourage you to seek personalized advice from qualified
            professionals regarding all personal finance issues. </p>

        </div>
      </div>
    </div>
  </div>