<app-taxes-top-menu></app-taxes-top-menu>
<app-jumbotron title="IFA Taxes Calculators"></app-jumbotron>


<div class="container mt-40">
    <div class="row">
        <div class="col-12">
            <h1>Tax Calculators</h1>
        </div>
    </div>
</div>
    
<div class="container mb-20">
    <div class="row">
        <div class="col-12">
            <div class=KJEReportTableDiv>
                <table class='KJEReportTable KJEIndexTable'>
                    <tbody>
                        <tr class="KJEEvenRow"><th class="KJELabel KJECellBorder KJECell30" scope=row><a routerLink="/taxes/calculators/tax1040" class="KJELabel">1040 Tax Calculator</a></th><td class="KJECell KJELeftPad">Enter your filing status, income, deductions and credits and we will estimate your total tax. Based on your projected tax withholding for the year, we can also estimate your tax refund or amount you may owe the IRS next April.</td></tr>
                        <tr class="KJEOddRow"><th class="KJELabel KJECellBorder KJECell30" scope=row><a routerLink="/taxes/calculators/charitabletaxsavings" class="KJELabel">Charitable Giving Tax Savings Calculator</a></th><td class="KJECell KJELeftPad">Use this calculator to determine your potential tax savings with a tax deductible gift to charity.  This calculator sorts through the tax brackets and filing options to estimate the actual tax savings of your charitable gift.</td></tr>
                        <tr class="KJEEvenRow"><th class="KJELabel KJECellBorder KJECell30" scope=row><a routerLink="/taxes/calculators/estateplan" class="KJELabel">Estate Tax Planning Calculator</a></th><td class="KJECell KJELeftPad">Knowing your potential estate tax liability is a great place to start your estate tax plan. Use this calculator to estimate your estate tax liability.</td></tr>
                        <tr class="KJEOddRow"><th class="KJELabel KJECellBorder KJECell30" scope=row><a routerLink="/taxes/calculators/socialsecuritytaxable" class="KJELabel">Social Security Taxable Benefits</a></th><td class="KJECell KJELeftPad">Use this calculator to determine if any of your Social Security benefits are taxable.</td></tr>
                        <tr class="KJEEvenRow"><th class="KJELabel KJECellBorder KJECell30" scope=row><a routerLink="/taxes/calculators/retiredistrib" class="KJELabel">Required Minimum Distribution (RMD)</a></th><td class="KJECell KJELeftPad">Use this calculator to determine your Required Minimum Distribution (RMD). </td></tr>
                        <tr class="KJEOddRow"><th class="KJELabel KJECellBorder KJECell30" scope=row><a routerLink="/calculators/tax-return-fee-calculator" class="KJELabel">Tax Return Fee Calculator</a></th><td class="KJECell KJELeftPad">Estimate your tax preparation fees based on specific forms or number of line items.</td></tr>
                    </tbody>
                </table>
            </div>
            <hr class="KJEFooterLine mt-5 mb-5">
            <div class="KJECenterText">
                <p><span class='KJEBold'>IFA Taxes</span><br>19200 Von Karman Ave Ste 100<br>Irvine, CA</p>
                <p class=KJEFooter>Information and interactive calculators are made available to you as self-help tools for your independent use and are not intended to provide investment advice. We cannot and do not guarantee their applicability or accuracy in regards to your individual circumstances. All examples are hypothetical and are for illustrative purposes.  We encourage you to seek personalized advice from qualified professionals regarding all personal finance issues. </p>
            </div>
        </div>
    </div>
</div>
    