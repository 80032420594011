<head>
    <title>Standard Mileage Rates for use of a car in 2020</title>
    <meta name="keywords" content="IFA Taxes charts, mileage writeoff chart, car mileage writeoff, mileage writeoff, mileage rates 2020">
    <meta name="description" content="The standard mileage rates for the use of a car (including vans and pickups or panel trucks) for tax writeoff.">
    <meta name="author" content="IFA Taxes">
    <meta name="robots" content="index, follow">
</head>
<app-taxes-top-menu></app-taxes-top-menu>
<app-jumbotron title="Standard Mileage Rates"></app-jumbotron>

<div class="container mt-40 mb-20">
    <div class="row">
        <div class="col-sm-12">
          <p class="mb-5">Beginning on January 1, 2025, the standard mileage rates for the use of a car (including vans and pickups or panel trucks) will be:</p>
        <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Year</th>
                  <th scope="col">For Use</th>
                  <th scope="col">Cents Per Mile</th>
                  <th scope="col">Other Details</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">2024</th>
                  <td>Business</td>
                  <td>67 cents per mile</td>
                  <td>Up 1.5 cents from the rate for 2023</td>
                </tr>
                <tr>
                  <th scope="row">2024</th>
                  <td>Medical or Moving</td>
                  <td>21 cents per mile</td>
                  <td>Down 1 cent from the rate for 2023</td>
                </tr>
                <tr>
                  <th scope="row">2024</th>
                  <td>Charitable Organizations</td>
                  <td>14 cents per mile</td>
                  <td>Unchanged from the rate for 2023</td>
                </tr>
                <tr>
                  <th scope="row">2023</th>
                  <td>Business</td>
                  <td>65.5 cents per mile</td>
                  <td>Up 3 cents from the rate for 2022</td>
                </tr>
                <tr>
                  <th scope="row">2023</th>
                  <td>Medical or Moving</td>
                  <td>22 cents per mile</td>
                  <td>Unchanged from the rate for 2022</td>
                </tr>
                <tr>
                  <th scope="row">2023</th>
                  <td>Charitable Organizations</td>
                  <td>14 cents per mile</td>
                  <td>Unchanged from the rate for 2022</td>
                </tr>
                </tbody>
              </table>
            </div>
            <p class="mt-5" style="color:rgb(112, 119, 123);"><strong>Note:</strong> Under the TCJA, taxpayers cannot claim a miscellaneous itemized deduction for unreimbursed employee automobile expenses. Only members of the Armed Forces on active duty moving under orders to a permanent change of station may claim a moving expense. (Rev. Proc. 2019-46)</p>
         </div>
      </div>
  </div>