<title>About IFA Taxes</title>
<app-taxes-top-menu></app-taxes-top-menu>

<app-jumbotron title="About IFA Taxes" icon="/assets/images/icons/menu/40x40/about-us.webp">
</app-jumbotron>


<div class="container mt-40 mb-20">
    <div class="row">
        <div class="col-12">
            <h2>Our Story</h2>
            <p>IFA Taxes is a division of <a href="https://www.ifa.com" target="_blank">Index Fund Advisors, Inc.</a> (IFA), a fee-only fiduciary advisor and wealth management firm registered with the United States Securities and Exchange Commission (SEC)*. It was created to assist clients in need of tax planning and tax return preparation expertise. IFA desired to create synergies for its clients by offering investment and accounting services. In addition, IFA Taxes offers its tax services to individuals and companies across the United States.</p>

            <p>The Director of Tax is Lisa Rimke, CPA**. Specializing in ultra-high net worth individuals, she has enjoyed helping people from all over the world in a personalized, boutique way. Her other tax experience has been in international, trusts and estates, sole proprietorships, S-Corporations and traditional C-Corporations and non-profits.</p>
                <p>While IFA Taxes provides a wide range of tax planning and tax preparation services, they do not provide auditing or attestation services and therefore are not a licensed CPA firm.</p> 
            <p id="aboutDisclaimer"><small>IRS Circular 230 Disclosure: To ensure compliance with requirements imposed by the IRS, we inform you that any U.S. Federal tax advice contained in this communication is not intended or written to be used, and cannot be used, for the purpose of (i) avoiding penalties under the Internal Revenue Code or (ii) promoting, marketing or recommending to another party any transaction or matter herein.</small></p>
            <p><small>* SEC registration does not constitute an endorsement of the firm by the Commission nor does it indicate that the adviser has attained a particular level of skill or ability.</small></p>
            <p><small>** Certified Public Accountant (CPA) is a license to provide accounting services to the public that is awarded by states upon passing their respective coursework requirements and the Uniform Certified Public Accounting Examination.</small></p>
        </div>
    </div>
</div>

<section>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 ml-auto mr-auto text-center">
            <h2>Our Team</h2>
            <h5>Decades of business and tax expertise. </h5>
            </div>
        </div>
        <div class="clearfix"></div>
        <div class="d-flex justify-content-evenly flex-wrap align-content-stretch">
            <div *ngFor="let taxteam of taxteams" class="col-lg-4 col-md-4 col-sm-6 col-12 mt-5 team-block">
                <!-- <div *ngFor="let taxteam of taxteams" class="mx-2"> -->
              <app-tax-team-card [data]="taxteam"></app-tax-team-card>
            </div>
        </div>
    
                  <div class="clearfix"></div> 
    </div>
</section>  
