<app-taxes-top-menu></app-taxes-top-menu>
<app-jumbotron title="IFA Taxes Articles" icon="assets/images/icons/menu/40x40/articles.webp">
</app-jumbotron>

<div class="container pt-5">
    <div class="jumbotron p-3 p-md-5 text-white rounded bg-dark" *ngIf="articleHeadline"><!-- *ngIf=articleHeadline -->
        <div class="row">
            <div class="col-12 col-lg-6">
                <h1 class="display-4">{{articleHeadline.title}}</h1>
                <div class="mb-1 text-light"><small>{{articleHeadline.date | date: "MMM d, yyyy"}}</small></div>
            <div [innerHTML]="articleHeadline.teaser" class="lead my-3"></div>
            <p class="lead mb-0"><a routerLink="/articles/{{articleHeadline.friendlyUrl}}" class="font-weight-bold">Continue reading...</a></p>
            </div>
            <div class="col-12 col-lg-6 ">
            <img class="card-img-right flex-auto d-none d-md-block img-fluid" src="{{articleHeadline.posterUrl}}" alt="">
            </div>
        </div>
    </div>
</div>
   
<div class="container mt-40" *ngIf="items!=undefined && items!=null && items.length>0">
    
    <div class="row mb-2" *ngIf="pageOfItems">

        <div *ngFor="let article of pageOfItems" class="col-md-6">
            <app-tax-article-card [data]="article"></app-tax-article-card>
        </div>
        <div class="clearfix mt-2"></div>

        <div class="pt-2 col-md-12">
          <app-tax-pagination [items]="articles" (changePage)="onChangePage($event)"></app-tax-pagination> 
        </div>
    </div>
</div>

