import { Component, Input, OnInit } from '@angular/core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-tax-team-card',
  templateUrl: './tax-team-card.component.html',
  styleUrls: ['./tax-team-card.component.scss'],
  standalone:false
})
export class TaxTeamCardComponent implements OnInit {

  faChevronRight = faChevronRight
  plainphone: string = "";
  plainemail: string = "";

  @Input() data: any;

  constructor() { }

  ngOnInit() {
    if (!this.data.url && this.data.content)
    {
      var j = JSON.parse(this.data.content)
      this.plainemail = j.email;
      this.plainphone = j.phone;
    }
  }

  openMail(eaddress: string)
  {
    location.href = "mailto:" + eaddress;
  } 

}
