<head>
    <title>Social Security Taxable Calculator</title>
    <meta name="keywords" content="Social Security Taxable Benefits Calculator, social security calculator, social security, social security tax calculator">
    <meta name="description" content="IFA Taxes Social Security Taxable Benefits Calculator helps you determine if any of your Social Security benefits are taxable.">
    <meta name="author" content="IFA Taxes">
    <meta name="robots" content="index, follow">
</head>

<app-taxes-top-menu></app-taxes-top-menu>
<app-jumbotron title="Social Security Taxable Calculator"></app-jumbotron>

<div class="container">
    <div class="row">
        <div class="col-12">
            <span id='KJECalculatorDescID'>
            Use this calculator to estimate how much of your Social Security benefit is subject to income taxes. For
            modest and low incomes, none of your Social Security benefit is subject to income taxes. However, once your
            income plus 50% of your Social Security benefit exceeds $32,000 for married couples filing jointly and
            $25,000 for everyone else, an ever increasing portion of your benefit is subject to income taxes. For higher
            incomes, up to 85% of your Social Security benefit is subject to incomes taxes and can have a significant
            impact on your net after-tax benefit.
            </span>
            <!--CALC_DESC_END-->
            <noscript>
            <div class="KJECenterText">
                <div id="KJENoJavaScript" class="KJENoJavaScript">Javascript is required for this calculator. If you are
                using Internet Explorer, you may need to select to 'Allow Blocked Content' to view this calculator.
                <p>For more information about these these financial calculators please visit: <a routerLink="http://www.dinkytown.net">Financial
                    Calculators from KJE Computer Solutions, Inc.</a></p>
                </div>
            </div>
            </noscript>
            <div id="KJEAllContent"></div>

            <br>
            <hr class="KJEFoooterLine">
            <div class="KJECenterText">
                <p><span class='KJEBold'>IFA Taxes, LLC.</span><br>19200 Von Karman Ave Ste 100<br>Irvine, CA</p>
                <p class="KJEFooter">Information and interactive calculators are made available to you as self-help tools for
                    your independent use and are not intended to provide investment advice. We cannot and do not guarantee
                    their applicability or accuracy in regards to your individual circumstances. All examples are hypothetical
                    and are for illustrative purposes. We encourage you to seek personalized advice from qualified
                    professionals regarding all personal finance issues. 
                </p>
            </div>
        </div>
    </div>
</div>
