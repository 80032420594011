<title>Frequently Asked Questions, IFA Taxes</title>
<app-taxes-top-menu></app-taxes-top-menu>
<app-jumbotron title="IFA Taxes FAQs" icon="/assets/images/icons/menu/40x40/faq.webp"></app-jumbotron>

 
  <section id="content" ng-controller="toggleAccordian">
    <div class="container mt-40 mb-20">
      <div class="row">
        <div class="col-12"><button class="btn btn-primary float-right" (click)="open = !open">{{ open? 'Close' : 'Open'}} this group</button></div>
        <div class="col-12" style="text-align: left;">
            <h2>Tax Updates for 2024 </h2>
            <accordion [closeOthers]="false" [isAnimated]="true" style="margin-top:15px">
                <accordion-group heading="What is the Due date to File your 2024 return?" [isOpen]='isOpen || open' #taxupdate>
                    <div accordion-heading class="xxx">
                        <span class="badge badge-primary float-right accordion-badge">
                            <i class="fa" [ngClass]="{'fa-chevron-down': taxupdate?.isOpen, 'fa-chevron-up': !taxupdate?.isOpen}"></i>
                        </span>
                      </div>
                      <p>File Form 1040 or 1040 SR by April 15, 2025.</p>
                </accordion-group>
                <!--accordion-group heading="Stimulus Payments" [isOpen]='isOpen || open' #taxupdate2>
                    <div accordion-heading>
                        <span class="badge badge-primary float-right accordion-badge">
                            <i class="fa" [ngClass]="{'fa-chevron-down': taxupdate2?.isOpen, 'fa-chevron-up': !taxupdate2?.isOpen}"></i>
                        </span>
                      </div>
                      <p>The IRS decided residents of the following states do not need to report <a href="https://www.irs.gov/newsroom/state-payments" target="_blank">these specific qualified payments</a> related to general welfare and disaster relief for federal income tax purposes of the year 2022:</p>
                      <ul>
                        <li>Alaska</li>
                        <li>California</li>
                        <li>Colorado</li>
                        <li>Connecticut</li>
                        <li>Delaware</li>
                        <li>Florida</li>
                        <li>Hawaii</li>
                        <li>Idaho</li>
                        <li>Illinois</li>
                        <li>Indiana</li>
                        <li>Maine</li>
                        <li>New Jersey</li>
                        <li>New Mexico</li>
                        <li>New York</li>
                        <li>Oregon</li>
                        <li>Pennsylvania</li>
                        <li>Rhode Island</li>
                      </ul>
                      
                      <p>Residents of the following states do not need to report the payment which is refund of state taxes paid and either the recipient claimed the standard deduction or itemized their deductions but did not receive a tax benefit, unless the recipient received a tax benefit in the year the taxes were deducted:</p>
                      <ul>
                        <li>Georgia</li>
                        <li>Massachusetts</li>
                        <li>South Carolina</li>
                        <li>Virginia</li>
                      </ul>
                        
                </accordion-group-->
                <accordion-group heading="Advanced Child Tax Credits" [isOpen]='isOpen || open' #taxupdate3>
                    <div accordion-heading>
                        <span class="badge badge-primary float-right accordion-badge">
                            <i class="fa" [ngClass]="{'fa-chevron-down': taxupdate3?.isOpen, 'fa-chevron-up': !taxupdate3?.isOpen}"></i>
                        </span>
                      </div>
                          <p>Please be advised there is no Advanced Child Tax Credits for year 2023 as it was for providing immediate relief during the pandemic.</p>
                        
                </accordion-group>
                <accordion-group heading="Online IRS Account" [isOpen]='isOpen || open' #taxupdate4>
                    <div accordion-heading>
                        <span class="badge badge-primary float-right accordion-badge">
                            <i class="fa" [ngClass]="{'fa-chevron-down': taxupdate4?.isOpen, 'fa-chevron-up': !taxupdate4?.isOpen}"></i>
                        </span>
                      </div>
                      
                          <p>We recommend that each of our clients set up their own IRS online account. With an online IRS account, you can view your account status, make payments (including balances due and estimate, among others), view past payments, confirm economic impact payments received, pull IRS transcripts, and view new IRS notices and letters you received, among other activities.</p>
                        <p>Accounts can be set up in as little as 10 minutes at www.irs.gov. If you have any trouble setting up an account, please contact our office and we can help walk you through the process. We can also help you set up your account in person during your tax appointment. If you are married, then each spouse should set up their own separate account.</p>
                </accordion-group>
  
                <accordion-group heading="Identity Protection Personal Identification Number (IP PIN)" [isOpen]='isOpen || open' #taxupdate5>
                    <div accordion-heading>
                        <span class="badge badge-primary float-right accordion-badge">
                            <i class="fa" [ngClass]="{'fa-chevron-down': taxupdate5?.isOpen, 'fa-chevron-up': !taxupdate5?.isOpen}"></i>
                        </span>
                      </div>
                      
                      <p>All taxpayers now eligible for Identity Protection PIN began in 2021. The IRS Identity Protection PIN (IP PIN) Opt-In Program has been expanded to all taxpayers who can properly verify their identity. An IP PIN helps prevent your social security number from being used to file a fraudulent federal income tax return. You can use the Get An IP PIN tool on www.IRS.gov/getanippin to request an IP PIN.</p>
                      <p>For tax year 2024, if you don't have an IP PIN, and your adjusted gross income on your last filed return is below $79,000 for Individuals, or $158,000 for Married filling Joint or less, the IRS also provides Form 15227 to apply for IP PIN. Otherwise make an appointment to visit a Taxpayer Assistance Center (TAC). You can locate the closest TAC by visiting <a href="https://apps.irs.gov/app/office-locator/" target="_blank">Taxpayer Assistance Center Office Locator at the IRS website.</a></p>
                        
                </accordion-group>
  
                <accordion-group heading="Business Meals" [isOpen]='isOpen || open' #taxupdate6>
                    <div accordion-heading>
                        <span class="badge badge-primary float-right accordion-badge">
                            <i class="fa" [ngClass]="{'fa-chevron-down': taxupdate6?.isOpen, 'fa-chevron-up': !taxupdate6?.isOpen}"></i>
                        </span>
                      </div>
                      
                      <p>The deduction for unreimbursed non-entertainment-related business meals is generally subject to a 50% limitation.</p>
                        
                </accordion-group>
              </accordion>
  
  </div>
  </div>
  
    <div class="clearfix mb-5"></div>
  
        <div class="row">
            <div class="col-12"><button class="btn btn-primary float-right" (click)="open1 = !open1">{{ open1? 'Close' : 'Open'}} this group</button></div>
            <div class="col-12">
                <h2>Tax Cuts & Jobs Act (TCJA) </h2>
                <accordion [closeOthers]="false" [isAnimated]="true">
                    <accordion-group heading="What are the new tax rates?" [isOpen]='isOpen || open1' #group>
                        <div accordion-heading>
                            <span class="badge badge-primary float-right accordion-badge">
                                <i class="fa" [ngClass]="{'fa-chevron-down': group?.isOpen, 'fa-chevron-up': !group?.isOpen}"></i>
                            </span>
                          </div>

                          For tax year <strong>2024:</strong>
                          <table class="table-responsive">
                            <table class="table">
                              <thead class="thead-dark">
                                <tr>
                                  <th scope="col">Rate</th>
                                  <th scope="col">Single</th>
                                  <th scope="col">Married Filing Jointly</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="bgc">
                                  <th scope="row">10%</th>
                                  <td>$0 – $11,600</td>
                                  <td>$0 – $23,200</td>
                                </tr>
                                <tr>
                                  <th scope="row">12%</th>
                                  <td>$11,600 - $47,150</td>
                                  <td>$23,201 - $94,300</td>
                                </tr>
                                <tr class="bgc">
                                  <th scope="row">22%</th>
                                  <td>$47,151 - $100,525</td>
                                  <td>$94,301 - $201,050</td>
                                </tr>
                                  <tr>
                                  <th scope="row">24%</th>
                                  <td>$100,526 - $191,950</td>
                                  <td>$201,051 - $383,900</td>
                                </tr>
                                <tr class="bgc">
                                  <th scope="row">32%</th>
                                  <td>$191,951 - $243,725</td>
                                  <td>$383,901 - $487,450</td>
                                </tr>
                                  <tr>
                                  <th scope="row">35%</th>
                                  <td>$243,726 - $609,350</td>
                                  <td>$487,451 - $731,200</td>
                                </tr>
                                <tr class="bgc">
                                  <th scope="row">37%</th>
                                  <td>$609,351 or more</td>
                                  <td>$731,201 or more</td>
                                </tr>
                              </tbody>
                            </table>
                          </table>
                          <br>
                          For tax year <strong>2023:</strong>
                          <table class="table-responsive">
                            <table class="table">
                              <thead class="thead-dark">
                                <tr>
                                  <th scope="col">Rate</th>
                                  <th scope="col">Single</th>
                                  <th scope="col">Married Filing Jointly</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="bgc3">
                                  <th scope="row">10%</th>
                                  <td>$0 – $11,000</td>
                                  <td>$0 – $22,000</td>
                                </tr>
                                <tr>
                                  <th scope="row">12%</th>
                                  <td>$11,000 - $44,725</td>
                                  <td>$22,000 - $89,450</td>
                                </tr>
                                <tr class="bgc3">
                                  <th scope="row">22%</th>
                                  <td>$44,725 - $93,375</td>
                                  <td>$89,450 - $190,750</td>
                                </tr>
                                  <tr>
                                  <th scope="row">24%</th>
                                  <td>$95,375 - $182,100</td>
                                  <td>$190,750 - $364,200</td>
                                </tr>
                                <tr class="bgc3">
                                  <th scope="row">32%</th>
                                  <td>$182,100 - $231,250</td>
                                  <td>$364,200 - $462,500</td>
                                </tr>
                                  <tr>
                                  <th scope="row">35%</th>
                                  <td>$231,250 - $578,125</td>
                                  <td>$462,500 - $693,750</td>
                                </tr>
                                <tr class="bgc3">
                                  <th scope="row">37%</th>
                                  <td>$578,125 or more</td>
                                  <td>$693,750 or more</td>
                                </tr>
                              </tbody>
                            </table>
                          </table>
                         
                    </accordion-group>
                    <accordion-group heading="What is the standard deduction?" [isOpen]='isOpen || open1' #group2>
                        <div accordion-heading>
                            <span class="badge badge-primary float-right accordion-badge">
                                <i class="fa" [ngClass]="{'fa-chevron-down': group2?.isOpen, 'fa-chevron-up': !group2?.isOpen}"></i>
                            </span>
                          </div>
  
                          <table class="table-responsive">
                            <table class="table">
                              <thead class="thead-dark">
                                <tr>
                                  <th scope="col">Year</th>
                                  <th scope="col">Single & Married Filing Jointly</th>
                                  <th scope="col">Married Filing Jointly</th>
                                  <th scope="col">Head of Household</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="bgc">
                                  <th scope="row">2024</th>
                                  <td>$14,600</td>
                                  <td>$29,200</td>
                                  <td>$21,900</td>
                                </tr>
                                <tr>
                                  <th scope="row">2023</th>
                                  <td>$13,850</td>
                                  <td>$27,700</td>
                                  <td>$20,800</td>
                                </tr>
                              </tbody>
                            </table>
                          </table>
                                
                    </accordion-group>
                    <accordion-group heading="What are the changes to itemized deductions?" [isOpen]='isOpen || open1' #group3>
                        <div accordion-heading>
                            <span class="badge badge-primary float-right accordion-badge">
                                <i class="fa" [ngClass]="{'fa-chevron-down': group3?.isOpen, 'fa-chevron-up': !group3?.isOpen}"></i>
                            </span>
                          </div>
                          
                              <ul>
                                  <li>Medical expenses will be deductible if they exceed 7.5% adjusted gross income.</li>
                                  <li>State and local taxes are now capped at $10,000.</li>
                                  <li>Interest on mortgages are fully deductible up to $750,000 on primary and secondary residences if purchased or refinanced after December 14, 2017.</li>
                                  <li>Miscellaneous deductions that exceed 2% adjusted gross income are no longer deductible they include:
                                      <ul>
                                          <li>Unreimbursed job expenses</li>
                                          <li>Investment expenses</li>
                                          <li>Tax preparation fees</li>
                                          <li>Fees to fight the IRS</li>
                                          <li>Hobby expenses</li>
                                      </ul>
                                  </li>
                                  <li>Casualty or theft losses will only be deductible if they occur as a result of a federally declared disaster. Only losses in excess of 10% of AGI are deductible after minus $100 from the loss amount.</li>
                                  <li>The Lifetime Learning Credit is phased out for taxpayers with modified adjusted gross income in excess of $80,000 ($160,000 for joint returns).</li>
                              </ul>
                            
                    </accordion-group>
                    <accordion-group heading="If I take the standard deduction on my federal return will it apply the same to my state tax return?" [isOpen]='isOpen || open1' #group4>
                        <div accordion-heading>
                            <span class="badge badge-primary float-right accordion-badge">
                                <i class="fa" [ngClass]="{'fa-chevron-down': group4?.isOpen, 'fa-chevron-up': !group4?.isOpen}"></i>
                            </span>
                          </div>
                          
                              No, depending on what state you live in you will want to check to see if your home state conforms to any of the TCJA. There will be cases where you will be taking the standard deduction on the federal tax return and will be itemizing on the state tax return.
                            
                    </accordion-group>
  
                    <accordion-group heading="What individual tax credits were affected?" [isOpen]='isOpen || open1' #group5>
                        <div accordion-heading>
                            <span class="badge badge-primary float-right accordion-badge">
                                <i class="fa" [ngClass]="{'fa-chevron-down': group5?.isOpen, 'fa-chevron-up': !group5?.isOpen}"></i>
                            </span>
                          </div>
                          
                          <!--p>Nearly all those extra benefits we saw during the pandemic have come to an end. Affected taxpayers will likely receive a smaller refund compared with the previous tax year.</p-->
                          <p>For tax year 2024 and 2023, the Child Tax Credit (CTC) is the same $2,000 if eligible.</p>
                          <p>For tax year 2024, the Earned Income Tax Credit (EITC), ranges from $632 to $7,830 if eligible. For tax year 2023, ranges from $600 to $7,430 if eligible</p>
                          <p>For tax year 2024, the Child and Dependent Care Credit is up to $3,000 for a single qualifying child, or up to $6,000 for two qualifying children or more.</p>
                            
                    </accordion-group>
  
                    <accordion-group heading="What is the Qualified Business Income Deduction?" [isOpen]='isOpen || open1' #group6>
                        <div accordion-heading>
                            <span class="badge badge-primary float-right accordion-badge">
                                <i class="fa" [ngClass]="{'fa-chevron-down': group6?.isOpen, 'fa-chevron-up': !group6?.isOpen}"></i>
                            </span>
                          </div>
                          
                          The new deduction also known as a Section 199A deduction is up to an additional 20% deduction on qualified business income from sole proprietorships and pass-through entities. Limits are based on income and type of business.
                            
                    </accordion-group>
  
                    <accordion-group heading="What is qualified business income (QBI)?" [isOpen]='isOpen || open1' #group7>
                        <div accordion-heading>
                            <span class="badge badge-primary float-right accordion-badge">
                                <i class="fa" [ngClass]="{'fa-chevron-down': group7?.isOpen, 'fa-chevron-up': !group7?.isOpen}"></i>
                            </span>
                          </div>
                          
                          QBI is the net amount of qualified income, gain, deduction, and loss. Only taxable items are counted, and only apply to U.S. businesses.
                            
                    </accordion-group>
  
                    <accordion-group heading="Did the meals and entertainment deduction change?" [isOpen]='isOpen || open1' #group8>
                        <div accordion-heading>
                            <span class="badge badge-primary float-right accordion-badge">
                                <i class="fa" [ngClass]="{'fa-chevron-down': group8?.isOpen, 'fa-chevron-up': !group8?.isOpen}"></i>
                            </span>
                          </div>
                          
                          Yes, the deduction has been eliminated for expenses related to entertainment, amusement, or recreation. However, food and beverage for the taxpayer or employee of the taxpayer present at the meeting will continue to be 50% deductible as long as the food or beverage are not lavish or extravagant.
                            
                    </accordion-group>
  
                    <accordion-group heading="Can I expense (Section 179) new equipment and improvements to my building?" [isOpen]='isOpen || open1' #group9>
                        <div accordion-heading>
                            <span class="badge badge-primary float-right accordion-badge">
                                <i class="fa" [ngClass]="{'fa-chevron-down': group9?.isOpen, 'fa-chevron-up': !group9?.isOpen}"></i>
                            </span>
                          </div>
                          
                          <p>For tax year 2024, you can deduct as much as your business's net income or $1,220,000 whichever is smaller for qualifying equipment. For tax year 2023, that cap is $1,160,000.</p>
                          <p>While businesses can get a larger tax return now with this deduction, they forfeit the chance to claim depreciation in the future. In other words, they can't spread that asset's cost over many years. That can result in fewer tax benefits in the future.</p>
                            
                    </accordion-group>
                  </accordion>
  
  
  
  </div>
  </div>
  
        <div class="clearfix mb-5"></div>
  <div class="row">
      <div class="col-12"><button class="btn btn-primary float-right" (click)="open2 = !open2">{{ open2? 'Close' : 'Open'}} this group</button></div>
            <div class="col-12">
                <h2>Retirement </h2>
                <accordion [closeOthers]="false" [isAnimated]="true">
                    <accordion-group heading="What are the retirement maximum contribution limits?" [isOpen]='isOpen || open2' #groups>
                        <div accordion-heading>
                            <span class="badge badge-primary float-right accordion-badge">
                                <i class="fa" [ngClass]="{'fa-chevron-down': groups?.isOpen, 'fa-chevron-up': !groups?.isOpen}"></i>
                            </span>
                          </div>
       
                            <table class="table-responsive">
                              <table class="table-responsive">
                                <table class="table">
                                  <thead class="thead-dark">
                                    <tr>
                                      <th scope="col">Type of Contribution</th>
                                      <th scope="col">Year 2024</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr class="bgc">
                                      <th scope="row">Defined Contribution maximum employee elective deferral (401(k), 403(b))</th>
                                      <td>$23,000</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Employee catch-up <strong>contribution</strong> (if age 50 or older by year-end)</th>
                                      <td>$7,500</td>
                                    </tr>
                                    <tr class="bgc">
                                      <th scope="row">Defined contribution maximum limit, all sources (includes maximum profit sharing)</th>
                                      <td>$69,000</td>
                                    </tr>
                                      <tr>
                                      <th scope="row">Defined Benefit Plan</th>
                                      <td>$275,000</td>
                                    </tr>
                                      <tr class="bgc">
                                      <th scope="row">Traditional and Roth IRA Plans</th>
                                      <td>$7,000</td>
                                    </tr>
                                      <tr>
                                      <th scope="row">IRA Catch-up contribution (if age 50 or older by year-end)</th>
                                      <td>$1,000</td>
                                    </tr>
                                      <tr class="bgc">
                                      <th scope="row">SEP IRA</th>
                                      <td>$69,000</td>
                                    </tr>
                                      <tr>
                                      <th scope="row">SIMPLE IRA</th>
                                      <td>$16,000</td>
                                    </tr>
                                      <tr class="bgc">
                                      <th scope="row">SIMPLE catch-up contribution (if age 50 or older by year-end)</th>
                                      <td>$3,500</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </table>
                              <table class="table">
                                <thead class="thead-dark">
                                  <tr>
                                    <th scope="col">Type of Contribution</th>
                                    <th scope="col">Year 2023</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr class="bgc3">
                                    <th scope="row">Defined Contribution maximum employee elective deferral (401(k), 403(b))</th>
                                    <td>$22,500</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Employee catch-up <strong>contribution</strong> (if age 50 or older by year-end)</th>
                                    <td>$7,500</td>
                                  </tr>
                                  <tr class="bgc3">
                                    <th scope="row">Defined contribution maximum limit, all sources (includes maximum profit sharing)</th>
                                    <td>$66,000</td>
                                  </tr>
                                    <tr>
                                    <th scope="row">Defined Benefit Plan</th>
                                    <td>$265,000</td>
                                  </tr>
                                  <tr class="bgc3">
                                    <th scope="row">Traditional and Roth IRA Plans</th>
                                    <td>$6,500</td>
                                  </tr>
                                    <tr>
                                    <th scope="row">IRA Catch-up contribution (if age 50 or older by year-end)</th>
                                    <td>$1,000</td>
                                  </tr>
                                  <tr class="bgc3">
                                    <th scope="row">SEP IRA</th>
                                    <td>$66,000</td>
                                  </tr>
                                    <tr>
                                    <th scope="row">SIMPLE IRA</th>
                                    <td>$15,500</td>
                                  </tr>
                                    <tr class="bgc3">
                                    <th scope="row">SIMPLE catch-up contribution (if age 50 or older by year-end)</th>
                                    <td>$3,500</td>
                                  </tr>
                                </tbody>
                              </table>
                            </table><br>
                    </accordion-group>
  
                    <accordion-group heading="What is the deadline to contribute to 401K?" [isOpen]='isOpen || open2' #groups1>
                        <div accordion-heading>
                            <span class="badge badge-primary float-right accordion-badge">
                                <i class="fa" [ngClass]="{'fa-chevron-down': groups1?.isOpen, 'fa-chevron-up': !groups1?.isOpen}"></i>
                            </span>
                          </div>
                          <p>For tax year 2024 is December 31, 2024.</p>
                          <p>For tax year 2023 is December 31, 2023.</p>
                    </accordion-group>

                    <accordion-group heading="What is the deadline to contribute to a Traditional and a Roth IRA?" [isOpen]='isOpen || open2' #groups10>
                      <div accordion-heading>
                          <span class="badge badge-primary float-right accordion-badge">
                              <i class="fa" [ngClass]="{'fa-chevron-down': groups1?.isOpen, 'fa-chevron-up': !groups1?.isOpen}"></i>
                          </span>
                        </div>
                        <p>For tax year 2024 is April 18, 2025.</p>
                        <p>For tax year 2023 is April 15, 2024.</p>
                    </accordion-group>

                    <accordion-group heading="When must I deposit a contribution into a SEP IRA?" [isOpen]='isOpen || open2' #groups2>
                        <div accordion-heading>
                            <span class="badge badge-primary float-right accordion-badge">
                                <i class="fa" [ngClass]="{'fa-chevron-down': groups2?.isOpen, 'fa-chevron-up': !groups2?.isOpen}"></i>
                            </span>
                          </div>
                          
                          According to the IRS, you must deposit contributions for a year by the due date (including extensions) for filing your federal income tax return for the year. If you obtain an extension for filing your tax return until October 15, you have until the end of that extension period to deposit the contribution, regardless of when you actually file the return.
                            
                    </accordion-group>
  
                    <accordion-group heading="What is a Qualified Charitable Deduction, and who is eligible to use it?" [isOpen]='isOpen || open2' #groups3>
                        <div accordion-heading>
                            <span class="badge badge-primary float-right accordion-badge">
                                <i class="fa" [ngClass]="{'fa-chevron-down': groups3?.isOpen, 'fa-chevron-up': !groups3?.isOpen}"></i>
                            </span>
                          </div>
                          
                          An individual who is at least 70.5 years old may exclude their retirement distribution from income up to $100,000 if they directly send their funds to a charity. Qualified charitable distributions (QCD) must be made directly from the IRA trustee to the public charity, distributions may not be made to private foundations, donor advised funds, charitable remainder trusts, or charitable annuities. Distributions from SIMPLE or SEP IRAs may qualify, but distributions from 401k and other employer-sponsored retirement plans do not.
                            
                    </accordion-group>
  
                    <accordion-group heading="Can I make an early withdrawal from a retirement plan prior to 59.5 without a penalty?" [isOpen]='isOpen || open2' #groups4>
                        <div accordion-heading>
                            <span class="badge badge-primary float-right accordion-badge">
                                <i class="fa" [ngClass]="{'fa-chevron-down': groups4?.isOpen, 'fa-chevron-up': !groups4?.isOpen}"></i>
                            </span>
                          </div>
                          
                          In most cases no. An amount distributed out of an IRA or other qualified plan must be contributed (rolled over) into another IRA or qualified plan within 60 days. There are also other limited exceptions to the 10% additional penalty for distributions, medical, and for first time homebuyers.
                            
                    </accordion-group>
  
                    <accordion-group heading="How is a non-deductible contribution into an IRA tracked?" [isOpen]='isOpen || open2' #groups5>
                        <div accordion-heading>
                            <span class="badge badge-primary float-right accordion-badge">
                                <i class="fa" [ngClass]="{'fa-chevron-down': groups5?.isOpen, 'fa-chevron-up': !groups5?.isOpen}"></i>
                            </span>
                          </div>
                          
                          Form 8606 should be filed annually with your tax return if a taxpayer has made any nondeductible contributions to a traditional IRA, and should continue to be filed every year after even if a contribution was not made during that year. Taxpayers should keep copies of these forms as evidence to show the IRS in case of audit. 
                            
                    </accordion-group>
  
                    <accordion-group heading="What is a backdoor Roth IRA?" [isOpen]='isOpen || open2' #groups6>
                        <div accordion-heading>
                            <span class="badge badge-primary float-right accordion-badge">
                                <i class="fa" [ngClass]="{'fa-chevron-down': groups6?.isOpen, 'fa-chevron-up': !groups6?.isOpen}"></i>
                            </span>
                          </div>
                          
                          It is a legal way for high-income earners to get around the income limits to contribute to a Roth IRA. This income limit for tax year 2024 is $161,000 for singles and $240,000 for married filing jointly or widowed. You'd contribute to a traditional IRA, then convert it to a Roth. There are no income limits for the conversion. Benefit of Roth IRA is the withdrawals in retirement are tax-free. 
                             
                    </accordion-group>
  
                    <accordion-group heading="How do I safely do a backdoor Roth IRA contribution?" [isOpen]='isOpen || open2' #groups7>
                        <div accordion-heading>
                            <span class="badge badge-primary float-right accordion-badge">
                                <i class="fa" [ngClass]="{'fa-chevron-down': groups7?.isOpen, 'fa-chevron-up': !groups7?.isOpen}"></i>
                            </span>
                          </div>
                          
                          <ol>
                              <li>Verify there are no other pre-tax (deductible) IRAs </li>
                              <li>If there are, roll over existing pre-tax IRAs to a 401(k) (if available) to avoid the IRA aggregation rule</li>
                              <li>Contribute to non-deductible IRA (if eligible)</li>
                              <li>Invest funds in the non-deductible IRA</li>
                              <li>Convert to Roth IRA</li>
                              <li>You can repeat steps 2-4 annually</li>
                          </ol>
                          
                    </accordion-group>
  
                    <accordion-group heading="Roth IRA and Traditional IRA Phaseout" [isOpen]='isOpen || open2' #groups8>
                        <div accordion-heading>
                            <span class="badge badge-primary float-right accordion-badge">
                                <i class="fa" [ngClass]="{'fa-chevron-down': groups8?.isOpen, 'fa-chevron-up': !groups8?.isOpen}"></i>
                            </span>
                          </div>
          
                          <table class="table-responsive">
                            <table class="table">
                              <thead class="thead-dark">
                                <tr>
                                  <th scope="col">Phaseout</th>
                                  <th scope="col">Year</th>
                                  <th scope="col">Single</th>
                                  <th scope="col">Married Filing Jointly</th>
                                  <th scope="col">Married Filing Separately</th>
                                  <th scope="col">Non-Active Participant Married To Active Participant</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="bgc">
                                  <th scope="row">IRA deduction phaseout for active participants</th>
                                    <td>2024</td>
                                    <td>$77,000-$87,000</td>
                                    <td>$123,000-$143,000</td>
                                    <td>$0-$10,000</td>
                                    <td>$230,000-$240,000</td>
                                </tr>
                                <tr>
                                  <th scope="row">Roth IRA phaseout</th>
                                    <td>2024</td>
                                    <td>$146,000-$161,000</td>
                                    <td>$230,000-$240,000</td>
                                    <td>$0-$10,000</td>
                                    <td>No Option</td>
                                </tr>
                              </tbody>
                            </table>
                          </table>
                          <br>
                          <table class="table-responsive">
                            <table class="table">
                              <thead class="thead-dark">
                                <tr>
                                  <th scope="col">Phaseout</th>
                                  <th scope="col">Year</th>
                                  <th scope="col">Single</th>
                                  <th scope="col">Married Filing Jointly</th>
                                  <th scope="col">Married Filing Separately</th>
                                  <th scope="col">Non-Active Participant Married To Active Participant</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="bgc3">
                                  <th scope="row">IRA deduction phaseout for active participants</th>
                                    <td>2023</td>
                                    <td>$73,000-$83,000</td>
                                    <td>$116,000-$136,000</td>
                                    <td>$0-$10,000</td>
                                    <td>$218,000-$228,000</td>
                                </tr>
                                <tr>
                                  <th scope="row">Roth IRA phaseout</th>
                                    <td>2023</td>
                                    <td>$138,000-$153,000</td>
                                    <td>$218,000-$228,000</td>
                                    <td>$0-$10,000</td>
                                    <td>No Option</td>
                                </tr>
                              </tbody>
                            </table>
                          </table>
                         
                    </accordion-group>
  
                    <accordion-group heading="HSA Contribution Limits" [isOpen]='isOpen || open2' #groups9>
                        <div accordion-heading>
                            <span class="badge badge-primary float-right accordion-badge">
                                <i class="fa" [ngClass]="{'fa-chevron-down': groups9?.isOpen, 'fa-chevron-up': !groups9?.isOpen}"></i>
                            </span>
                          </div>

                          <table class="table-responsive">
                            <table class="table">
                              <thead class="thead-dark">
                                <tr>
                                  <th scope="col">HSA</th>
                                  <th scope="col">Year 2024</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="bgc">
                                  <th scope="row">Self-only HSA contribution limit</th>
                                  <td>$4,150</td>
                                </tr>
                                <tr>
                                  <th scope="row">Family HSA contribution limit</th>
                                  <td>$8,300</td>
                                </tr>
                                <tr>
                                  <th scope="row">Aged 55 and older catch-up limit</th>
                                  <td>$1,000</td>
                                </tr>
                              </tbody>
                            </table>
                          </table>
                          <table class="table-responsive">
                            <table class="table">
                              <thead class="thead-dark">
                                <tr>
                                  <th scope="col">HSA</th>
                                  <th scope="col">Year 2023</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="bgc3">
                                  <th scope="row">Self-only HSA contribution limit</th>
                                  <td>$3,850</td>
                                </tr>
                                <tr>
                                  <th scope="row">Family HSA contribution limit</th>
                                  <td>$7,750</td>
                                </tr>
                              </tbody>
                            </table>
                          </table><br>
                    </accordion-group>
  
                    </accordion>
  </div>
  </div>
  
        
  <div class="clearfix mb-5"></div>
  <div class="row">
      <div class="col-12"><button class="btn btn-primary float-right" (click)="open3 = !open3">{{ open3? 'Close' : 'Open'}} this group</button></div>
            <div class="col-12">
                <h2>Investment </h2>
  
                <accordion [closeOthers]="false" [isAnimated]="true">
                    <accordion-group heading="Taxable Income Capital Gains Rates" [isOpen]='isOpen || open3' #grouped>
                        <div accordion-heading>
                            <span class="badge badge-primary float-right accordion-badge">
                                <i class="fa" [ngClass]="{'fa-chevron-down': grouped?.isOpen, 'fa-chevron-up': !grouped?.isOpen}"></i>
                            </span>
                          </div>
      
                          For tax year <strong>2024</strong>  
                          <table class="table-responsive">
                              <table class="table">
                              <thead class="thead-dark">
                                <tr>
                                  <th scope="col">Long-Term Capital Gains Rate</th>
                                  <th scope="col">Single Taxpayers</th>
                                  <th scope="col">Married Filing Jointly</th>
                                  <th scope="col">Head of Household</th>
                                  <th scope="col">Married Filing Separately</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="bgc">
                                  <th scope="row">0%</th>
                                  <td>up to $47,025</td>
                                  <td>up to $94,050</td>
                                  <td>up to $63,000</td>
                                  <td>up to $47,025</td>
                                </tr>
                                <tr>
                                  <th scope="row">15%</th>
                                  <td>$47,026 to $518,900</td>
                                  <td>$94,051 to $583,750</td>
                                  <td>$63,001 to $551,350</td>
                                  <td>$47,026 to $291,850</td>
                                </tr>
                                <tr class="bgc">
                                  <th scope="row">20%</th>
                                  <td>$518,901 or more</td>
                                  <td>$583,751 or more</td>
                                  <td>$551,351 or more</td>
                                  <td>$291,851 or more</td>
                                </tr>
                              </tbody>
                            </table>
                          </table>
                          <br>
                          For tax year <strong>2023</strong>  
                          <table class="table-responsive">
                              <table class="table">
                              <thead class="thead-dark">
                                <tr>
                                  <th scope="col">Long-Term Capital Gains Rate</th>
                                  <th scope="col">Single Taxpayers</th>
                                  <th scope="col">Married Filing Jointly</th>
                                  <th scope="col">Head of Household</th>
                                  <th scope="col">Married Filing Separately</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="bgc3">
                                  <th scope="row">0%</th>
                                  <td>up to $44,625</td>
                                  <td>up to $89,250</td>
                                  <td>up to $59,750</td>
                                  <td>up to $44,625</td>
                                </tr>
                                <tr>
                                  <th scope="row">15%</th>
                                  <td>$44,626 to $492,300</td>
                                  <td>$89,251 to $553,850</td>
                                  <td>$59,751 to $523,050</td>
                                  <td>$44,626 to $276,900</td>
                                </tr>
                                <tr class="bgc3">
                                  <th scope="row">20%</th>
                                  <td>$492,301 or more</td>
                                  <td>$553,851 or more</td>
                                  <td>$523,051 or more</td>
                                  <td>$276,901 or more</td>
                                </tr>
                              </tbody>
                            </table>
                          </table>
                          
                          
                        </accordion-group>
  
                        <accordion-group heading="What happens when I have capital losses?" [isOpen]='isOpen || open3' #grouped1>
                            <div accordion-heading>
                                <span class="badge badge-primary float-right accordion-badge">
                                    <i class="fa" [ngClass]="{'fa-chevron-down': grouped1?.isOpen, 'fa-chevron-up': !grouped1?.isOpen}"></i>
                                </span>
                              </div>
                              <h6>Capital losses can be used to offset capital gains.</h6>
            <p>Short-term losses must first offset short-term gains (if any). Long-term losses must first offset long-term gains (if any). Once that is done and there are still leftover losses, then it can be applied to either short-term or long-term gains.</p>
            <p>Example: A client has $20,000 in short-term carryover loss and a $10,000 in long-term carryover. During the year a position was sold for a $5,000 gain and had long-term capital gains dividends of $15,000. The results would the long-term loss carryover of $10,000 would be fully offset and the remaining short-term carryover would be reduced from $20,000 to $10,000. </p>
  
            <h6>Capital losses exceed capital gains in the current year.</h6>
            <p>Excess capital losses can offset up to $3,000 in ordinary income per year on your tax return ($1,500 if Married Filing Separately). Excess short-term losses (if any) will get offset before any excess long-term capital losses. These losses are tracked on Schedule D of the Individual (Form 1040) tax return.</p>
            <p>Example (Continued): From our previous example $3,000 of $10,000 in short-term capital carryover will then be utilized to offset ordinary income. $7,000 will now be carried forward to future tax years.  </p> 
                        </accordion-group>
  
                      </accordion>
  </div>
  </div>
  
        <div class="clearfix mb-5"></div>
  <div class="row">
      <div class="col-12"><button class="btn btn-primary float-right" (click)="open4 = !open4">{{ open4? 'Close' : 'Open'}} this group</button></div>
            <div class="col-12">
                <h2>Dividends </h2>
  
                <accordion [closeOthers]="false" [isAnimated]="true">
                    <accordion-group heading="Dividend Tax Rates" [isOpen]='isOpen || open4' #grouping>
                        <div accordion-heading>
                            <span class="badge badge-primary float-right accordion-badge">
                                <i class="fa" [ngClass]="{'fa-chevron-down': grouping?.isOpen, 'fa-chevron-up': !grouping?.isOpen}"></i>
                            </span>
                          </div>
                   
              <p>If your dividends meet the definition of <a href="https://www.fool.com/investing/stock-market/types-of-stocks/dividend-stocks/qualified-dividends/" target="_blank">qualified dividends</a>, they will be taxed at a rate of 0%, 15%, or 20%, depending on your adjusted gross income, or AGI. </p>

              For tax year <strong>2024</strong>:
              <table class="table-responsive">
              <table class="table">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Qualified Dividend Tax Rate</th>
                  <th scope="col">Single Filing Status</th>
                  <th scope="col">Married Filing Jointly</th>
                  <th scope="col">Head of Household</th>
                  <th scope="col">Married Filing Separately</th>
                </tr>
              </thead>
              <tbody>
                    <tr class="bgc">
                      <th scope="row">0%</th>
                        <td>up to $47,025</td>
                        <td>up to $94,050</td>
                        <td>up to $63,000</td>
                        <td>up to $47,025</td>
                    </tr>
                    <tr>
                      <th scope="row">15%</th>
                        <td>$47,026 to $518,900</td>
                        <td>$94,051 to $583,750</td>
                        <td>$63,001 to $551,350</td>
                        <td>$47,026 to $291,850</td>
                    </tr>
                    <tr class="bgc">
                      <th scope="row">20%</th>
                        <td>$518,901 or more</td>
                        <td>$583,751 or more</td>
                        <td>$551,351 or more</td>
                        <td>$291,851 or more</td>
                    </tr>
              </tbody>
              </table>
              </table>
              <br>
              For tax year <strong>2023</strong>:
              <table class="table-responsive">
                <table class="table">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">Qualified Dividend Tax Rate</th>
                    <th scope="col">Single Filing Status</th>
                    <th scope="col">Married Filing Jointly</th>
                    <th scope="col">Head of Household</th>
                    <th scope="col">Married Filing Separately</th>
                  </tr>
                </thead>
                <tbody>
                    <tr class="bgc3">
                        <th scope="row">0%</th>
                        <td>up to $44,625</td>
                        <td>up to $89,250</td>
                          <td>up to $59,750</td>
                          <td>up to $44,625</td>
                      </tr>
                      <tr>
                        <th scope="row">15%</th>
                        <td>$44,676 to $492,300</td>
                        <td>$89,250 to $553,850</td>
                          <td>$59,750 to $523,050</td>
                          <td>$44,626 to $276,900</td>
                      </tr>
                      <tr class="bgc3">
                        <th scope="row">20%</th>
                        <td>$492,301 or more</td>
                        <td>$553,851 or more</td>
                          <td>$523,051 or more</td>
                          <td>$276,901 or more</td>
                      </tr>
                </tbody>
              </table>
              </table>

              <p>If your dividends do not qualify, they will be taxed at your marginal tax rate, according to the 2022 tax brackets. As part of the tax overhaul, the seven brackets have been adjusted to 10%, 12%, 22%, 24%, 32%, 35%, and 37%.</p>
            </accordion-group>
  
                    <accordion-group heading="Another Tax High-Income Investors May Have To Pay" [isOpen]='isOpen || open4' #grouping1>
                        <div accordion-heading>
                            <span class="badge badge-primary float-right accordion-badge">
                                <i class="fa" [ngClass]="{'fa-chevron-down': grouping1?.isOpen, 'fa-chevron-up': !grouping1?.isOpen}"></i>
                            </span>
                          </div>
                          <p>On top of the tax rates discussed in the previous section and any applicable state taxes you might owe, high-income investors are also required to pay a 3.8% net investment income tax.</p>
              <p>Investors will owe net investment income tax if they have any net investment income (interest, dividends, capital gains, etc.) and have modified AGI in excess of these thresholds:</p>
              <table class="table-responsive">
              <table class="table">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Tax Filing Status</th>
                  <th scope="col">MAGI Threshold</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Single</td>
                  <td>$200,000</td>
                </tr>
                <tr>
                  <td>Married filing jointly or qualifying widow(er)</td>
                  <td>$250,000</td>
                </tr>
                  <tr>
                  <td>Head of household</td>
                  <td>$200,000</td>
                </tr>
                  <tr>
                  <td>Married filing separately</td>
                  <td>$125,000</td>
                </tr>
              </tbody>
            </table>
            </table>
              <p>Finally, it's important to note that unlike the tax brackets, these thresholds are not indexed for inflation. So, they'll remain the same unless the law governing them changes.</p>
                    </accordion-group>
  
  
                 </accordion>
  </div>
  </div>
  
  <div class="clearfix mb-5"></div>
  <div class="row">
      <div class="col-12"><button class="btn btn-primary float-right" (click)="open5 = !open5">{{ open5? 'Close' : 'Open'}} this group</button></div>
            <div class="col-12">
                <h2>Education Savings </h2>
                <accordion [closeOthers]="false" [isAnimated]="true">
                    <accordion-group heading="What is a 529 Plan?" [isOpen]='isOpen || open5' #groupEducation>
                        <div accordion-heading>
                            <span class="badge badge-primary float-right accordion-badge">
                                <i class="fa" [ngClass]="{'fa-chevron-down': groupEducation?.isOpen, 'fa-chevron-up': !groupEducation?.isOpen}"></i>
                            </span>
                          </div>
                          <p>A 529 plan is an education savings plan for college qualified expenses and K-12 tuition only that allows contributions to grow tax free similar to a Roth IRA.
                              See chart below to see if your state has any additional tax benefits.
                           </p>
                          
                           <p><a href="/taxes/charts/chart529" target="_blank" class="btn btn-primary btn-lg active" role="button" aria-pressed="true">529 Plan Tax Benefit Chart</a></p>
                           <p>&nbsp;</p>
                    </accordion-group>
  
                    <accordion-group heading="What is a qualified education expense?" [isOpen]='isOpen || open5' #groupEducation1>
                        <div accordion-heading>
                            <span class="badge badge-primary float-right accordion-badge">
                                <i class="fa" [ngClass]="{'fa-chevron-down': groupEducation1?.isOpen, 'fa-chevron-up': !groupEducation1?.isOpen}"></i>
                            </span>
                          </div>
                          <p>Tuition and fees, books and supplies, computer and internet access, room and board, special needs equipment. If the student lives off campus please refer to the colleges cost of attendance figures to determine the room and board amount.</p>
                    </accordion-group>
  
                    <accordion-group heading="What if my child/beneficiary doesn't use the 529 plan?" [isOpen]='isOpen || open5' #groupEducation2>
                        <div accordion-heading>
                            <span class="badge badge-primary float-right accordion-badge">
                                <i class="fa" [ngClass]="{'fa-chevron-down': groupEducation2?.isOpen, 'fa-chevron-up': !groupEducation2?.isOpen}"></i>
                            </span>
                          </div>
                          <p>Generally, you will pay federal and state income tax and a penalty of the earnings portion of a non-qualified withdrawal. The penalty exceptions are: the beneficiary receives a scholarship, attends a U.S. military academy, dies or becomes disabled.</p>
                    </accordion-group>
  
                    <accordion-group heading="What happens if there are funds left over in the 529 plan?" [isOpen]='isOpen || open5' #groupEducation3>
                      <div accordion-heading>
                          <span class="badge badge-primary float-right accordion-badge">
                              <i class="fa" [ngClass]="{'fa-chevron-down': groupEducation3?.isOpen, 'fa-chevron-up': !groupEducation3?.isOpen}"></i>
                          </span>
                        </div>
                        <p>To avoid paying tax and penalties on your earnings your options are: change of beneficiary to a qualifying family member, keep the funds in the account in case the beneficiary wants to attend grad school, make yourself the beneficiary and use the funds for furthering your own education, and roll funds into a 529 ABLE account.</p>
                  </accordion-group>
  
                </accordion>
  </div>
  </div>
  
  <div class="clearfix mb-5"></div>
  <div class="row">
      <div class="col-12"><button class="btn btn-primary float-right" (click)="open6 = !open6">{{ open6? 'Close' : 'Open'}} this group</button></div>
            <div class="col-12">
                <h2>Social Security </h2>
                <accordion [closeOthers]="false" [isAnimated]="true">
                    <accordion-group heading="What percentage of your social security benefits are taxed?" [isOpen]='isOpen || open6' #groupEducation>
                        <div accordion-heading>
                            <span class="badge badge-primary float-right accordion-badge">
                                <i class="fa" [ngClass]="{'fa-chevron-down': groupEducation?.isOpen, 'fa-chevron-up': !groupEducation?.isOpen}"></i>
                            </span>
                          </div>
                          <table class="table-responsive">
                            <table class="table">
                            <thead class="thead-dark">
                              <tr>
                                <th scope="col">Percentage of Your Benefits Taxed</th>
                                <th scope="col">Combined Income for Individuals</th>
                                <th scope="col">Combined Income for Married Couples Filing Jointly</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>0%</td>
                                <td>Less than $25,000 per year</td>
                                <td>Less than $32,000 per year </td>
                              </tr>
                              <tr>
                                <td>Up to 50%</td>
                                <td>$25,000 to $34,000 per year</td>
                                <td>$32,000 to $44,000 per year</td>
                              </tr>
                                <tr>
                                <td>Up to 85%</td>
                                <td>More than $34,000 per year</td>
                                <td>More than $44,000 per year</td>
                              </tr>
                            </tbody>
                          </table>
                          </table>
                          <p><strong>Example</strong>: Your combined income which = Adjusted Gross Income + Nontaxable Interest + ½ of Annual Social Security Benefit.</p>
  
                          <p>If you receive $30,000 in social security benefits and withdraw $40,000 from your Pre Tax 401k/Traditional IRA and a married couple filing jointly:</p>
                          <div class="textIndent">
                          <p>Annual Combined Income = ( ½ x 30,000) + 40,000</p>
                          <p>Annual Combined Income = 15,000 + 40,000</p>
                          <p>Annual Combined Income = 55,000  </p>
                          </div> 
                            
                          <p>According to the chart, 85% of your $30,000 in social security benefits will be taxed.</p>
  
                    </accordion-group>
  
                </accordion>
  </div>
  </div>
  
        <div class="clearfix mt-5 mb-5"></div>
  <p> <strong>Published 01/2025 Disclosures:</strong>  Data is provided for informational purposes only and should not be construed as tax advice. Information provided here is subject to change.  Please visit the IRS website for additional information, details and updates.</p>
  
  </div>
  </section>