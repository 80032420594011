import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-tax-home-article-card',
  templateUrl: './tax-home-article-card.component.html',
  styleUrls: ['./tax-home-article-card.component.scss'],
  standalone:false
})
export class TaxHomeArticleCardComponent implements OnInit 
{

  @Input() data: any;
  constructor() { }
  ngOnInit(): void {  }

}
