import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-taxlogin',
  templateUrl: './taxlogin.component.html',
  styleUrls: ['./taxlogin.component.scss'],
  standalone:false
})
export class TaxloginComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
