
<div class="card">
    <a routerLink="/articles/{{data.friendlyUrl}}">
        <img class="card-img-top" src="{{data.posterUrl}}" alt=""/>
        <div style="padding: 20px;">
            <h5 class="card-title"><b>{{data.title}}</b></h5>
            <div [innerHTML]="data.teaser" class="card-text"></div>
            <p class="card-text"><small class="text-muted">{{data.date | date: "MMM d, yyyy"}}</small></p>
        </div>
    </a>
</div>