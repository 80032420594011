<head>
    <title>Tax Charts</title>
    <meta name="keywords" content="IFA Taxes charts, charts, tax charts, taxes charts">
    <meta name="description" content="A list of tax charts for maximum contributions for tax plans broken down by state.">
    <meta name="author" content="IFA Taxes">
    <meta name="robots" content="index, follow">
</head>
<app-taxes-top-menu></app-taxes-top-menu>
<app-jumbotron title="Tax Benefit Charts"></app-jumbotron>

<div class="container mt-40 mb-20">
    <div class="row">
        <div class="col-md-4 offset-md-2">
          <div class="card text-center">
            <div>
                <img class="card-img-top" src="https://services.ifa.com/art/images/2307_2022-10-4-10-4-53.jpg" alt="529 Plan Chart">
            </div>
            <div class="card-body">
              <h5 class="card-title">529 Plan Tax Benefit List</h5>
              <p class="card-text">View the state income tax benefits for all 50 states.</p>
              <a routerLink="chart529" class="btn btn-primary">View Chart</a>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card text-center">
            <img class="card-img-top" src="https://services.ifa.com/art/images/2308_2022-10-4-10-49-20.jpg" alt="Standard Mileage Rates Chart">
            <div class="card-body">
              <h5 class="card-title">Standard Mileage Rates</h5>
              <p class="card-text">View the standard mileage rates for the use of a car.</p>
              <a routerLink="chartmileage" class="btn btn-primary">View Chart</a>
            </div>
          </div>
        </div>
    </div>
</div>