<head>
    <title>Contact IFA Taxes</title>
    <meta name="description" content="Connect with an IFA Tax Specialist" />
  </head>
  <app-taxes-top-menu></app-taxes-top-menu>

  <app-jumbotron title="Contact IFA Taxes" icon="/assets/images/icons/menu/40x40/contact-us.webp"></app-jumbotron>
  
  <div class="container">
    <div class="row become-row mb-40 mt-20">
  
      
        <div class="col-md-4">
          <form [formGroup]="fgBC" (ngSubmit)="submitTaxesContact()">
          <div class="row mt-20 become-row">
              <div class="col-lg-12 pad-both-0">
                  <label for="name_first" class="width-100 pull-left font-open-h font-16 pad-r-5 ">First Name:</label>
                  <input type="text" class="fc width-100 pull-left box-shadow-no background-l-l-gray form-control mb-0 border-none border-radius-none" id="name_first" formControlName="name_first" />
                  <span *ngIf="fgBC.controls['name_first'].invalid && (fgBC.controls['name_first'].dirty || fgBC.controls['name_first'].touched)" class="errorHint">
                      First Name required.
                  </span>
              </div>
  
              <div class="col-lg-12 pad-both-0">
                  <label for="name_last" class="width-100 pull-left font-open-h font-16 pad-r-5">Last Name:</label>
                  <input type="text" class="fc width-100 pull-left box-shadow-no background-l-l-gray form-control mb-0 border-none border-radius-none" id="name_last" formControlName="name_last"/>
                  <span *ngIf="fgBC.controls['name_last'].invalid && (fgBC.controls['name_last'].dirty || fgBC.controls['name_last'].touched)" class="errorHint">
                      Last Name required.
                  </span>
              </div>
  
              <div class="col-lg-12 pad-both-0">
                  <label for="email" class="width-100 pull-left font-open-h font-16 pad-r-5 ">Email:</label>
                  <input type="email" class="fc width-100 pull-left box-shadow-no background-l-l-gray form-control mb-0 border-none border-radius-none" id="email" formControlName="email" maxlength="100"/>
                  <span *ngIf="fgBC.controls['email'].invalid && (fgBC.controls['email'].dirty || fgBC.controls['email'].touched)" class="errorHint">
                      Email required.
                  </span>
              </div>
  
              <div class="col-lg-12 pad-both-0">
                  <label for="phone" class="width-100 pull-left font-open-h font-16 pad-r-5 " >Phone:</label>
                  <input type="text" maxlength="15" mask="000000099999999" mask="000-000-099999999" class="fc width-100 pull-left box-shadow-no background-l-l-gray form-control mb-0 border-none border-radius-none" id="phone" formControlName="phone"/>
                  <span *ngIf="fgBC.controls['phone'].invalid && (fgBC.controls['phone'].dirty || fgBC.controls['phone'].touched)" class="errorHint">
                      Phone required.
                  </span>
              </div>
            
          
              <div class="col-lg-12 pad-both-0">
                  <label for="message" class="width-100 pull-left font-open-h font-16 pad-r-5 ">Questions or Comments:</label>
                  <textarea id="message" class="fc width-100 pull-left box-shadow-no background-l-l-gray form-control mb-0 border-none border-radius-none" style="min-height: 125px" formControlName="message" maxlength="500">
                  </textarea>
              </div>
          </div>
          <div class="text-center my-5" style="width:304px">
            <re-captcha formControlName="captchaToken" (resolved)="addTokenLog($event)">
            </re-captcha>     
            <div><span id="time" *ngIf="captchaToken!=null" class="text-danger"></span>&nbsp;</div>        
            <span *ngIf="captchaToken==null" class="errorHint">
              Please complete the CAPTCHA
          </span>
          </div>
          <!--div class="row mt-30 become-row">
              <div class="blue-button width-100 pull-left font-16 contact_send">Connect with an IFA Advisor</div-->
              <button type="submit" [disabled]="!fgBC.valid || submitting" [ngClass]="(fgBC.valid && !submitting) ? 'blue-button':'disable-blue-button'" class="mb-40 center-block mt-20 font-w font-20 text-center" style="width: 430px;" >Connect with an IFA Tax Specialist</button>
          <!--/div  && captchaToken!=null -->
        </form>
        </div>
        <div class="col-md-8 mt-3 ps-5">
            <p>IFA Taxes is a division of <a _ngcontent-c7="" href="https://www.ifa.com" target="_blank">Index Fund Advisors, Inc.</a> (IFA), a fee-only fiduciary advisor and wealth management firm registered with the United States Security and Exchange Commission *(SEC). It was created to assist clients in need of tax planning and tax return preparation expertise. IFA desired to create synergies for its clients by offering investment, tax and accounting services. In addition, IFA Taxes offers its tax and accounting services to individuals and companies across the United States.</p>
            <p>While IFA Taxes provides a wide range of tax planning and tax preparation services, they do not provide auditing or attestation services and therefore are not a licensed CPA firm.</p>
            <p id="aboutDisclaimer">IRS Circular 230 Disclosure: To ensure compliance with imposed by the IRS, we inform you that any U.S. Federal tax advice contained in this communication is not intended or written to be used, and cannot be used, for the purpose of (i) avoiding penalties under the Internal Revenue Code or (ii) promoting, marketing or recommending to another party any transaction or matter herein.</p>
            <p><small>* SEC registration does not constitute an endorsement of the firm by the Commission nor does it indicate that the adviser has attained a particular level of skill or ability.  </small></p>
            <p><small>* Certified Public Accountant (CPA) is a license to provide accounting services to the public awarded by states upon passing their respective course work requirements and the Uniform Certified Public Accounting Examination.  </small></p>
      
        </div>
      
      
    </div>
  </div>
  