import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tax-article-card',
  templateUrl: './tax-article-card.component.html',
  styleUrls: ['./tax-article-card.component.scss'],
  standalone:false
})
export class TaxArticleCardComponent implements OnInit 
{
  @Input() data: any;
  constructor() { }
  ngOnInit(): void {
  }

}
