import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataServiceService } from '../../../services/data/data-service.service';
import { Subscription } from 'rxjs';
import { HeadService } from 'src/app/services/head.service';

@Component({
  selector: 'app-taxarticles',
  templateUrl: './taxarticles.component.html',
  styleUrls: ['./taxarticles.component.scss'],
  standalone:false
})
export class TaxarticlesComponent implements OnInit, OnDestroy 
{
  private subs: Subscription = new Subscription();
  public articleHeadline: any;
  public articles: any;
  items?: any[];
  public pageOfItems?: Array<any>;

  constructor(private ds: DataServiceService, private head:HeadService) 
  { 
    this.head.setTitle("Tax Articles for Individuals and Business Owners on Tax Changes, Tax Cuts, Tax Tips, and Tax Programs.");
    this.head.setKeywords("top tax articles, tax articles, tax articles small business, tax changes, tax tips, tax programs, tax cuts");
    this.head.setDescription("Articles on taxes, tax cuts, personal finance, tax options, retirement, tax planning, and tax nightmares.");
    this.head.setMetaProperty("author","IFA Taxes");
    this.head.setMetaProperty("robots","index, follow");
  
    this.subs = this.ds.getArticleTax()
                  .subscribe
                  (
                    resp => 
                    {
                      resp = resp.map( x => { x.posterUrl = x.posterUrl.replace("177x100", "512x288"); return x });
                      this.articleHeadline = resp[0];
                      
                      this.articles = resp;
                      this.articles.shift();

                      this.items = Array(this.articles).fill(0).map((x, i) => ({ id: (i + 1), name: `Item ${i + 1}`}));
                      this.pageOfItems = this.items;
                    }
                  );

  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void 
  {
    this.subs.unsubscribe();  
  }

  onChangePage(pageOfItems: Array<any>) 
  {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

}
