import { Component, OnInit, ViewEncapsulation, AfterViewInit, Inject, PLATFORM_ID } from '@angular/core';
import { DynamicScriptLoaderServiceTaxes } from '../../svc/dynamic-script-loader.service' //'src/app/services/DynamicScriptLoader.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-tax1040',
  templateUrl: './tax1040.component.html',
  styleUrls: ['./tax1040.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone:false,
  host: {ngSkipHydration: 'true'}

})
export class Tax1040Component implements OnInit {

  constructor(private dynamicScriptLoader: DynamicScriptLoaderServiceTaxes, @Inject(PLATFORM_ID) private platformId: Object) { }

  _window() : any {
    // return the global native browser window object
    if (isPlatformBrowser(this.platformId)) {  
      return window;
    }
 }

  ngOnInit() {   }
  
  ngAfterViewInit(){
    if (isPlatformBrowser(this.platformId)) {  
      //safe now to use document/window (ssr)
        this.loadScripts();
    }   
  }

  private loadScripts() {
    // You can load multiple scripts by just providing the key as argument into load method of the service
    this.dynamicScriptLoader.load('kje').then((data:any) => {
      // Script Loaded Successfully
      console.log('script loaded sucessfully', data);
      this.dynamicScriptLoader.load( 'kje-specific').then((data:any) => {
      // Script Loaded Successfully
      console.log('script loaded sucessfully', data);
      this.dynamicScriptLoader.load('tax-1040').then((data:any) => {
        // Script Loaded Successfully
        console.log('script loaded sucessfully', data);
        this.dynamicScriptLoader.load('tax-1040-params').then((data:any) => {
          // Script Loaded Successfully
          console.log('script loaded sucessfully', data);
           //HAXOR
           var K = this._window().KJE;
           if(K!=null && K.initFired !=null && K.initFired == false && K.init !=null){
             K.init();
           }      
           //HAXOR
        }).catch((error:any) => console.log(error));
        
      }).catch((error:any) => console.log(error));

    }).catch((error:any) => console.log(error));

    }).catch((error:any) => console.log(error));
  }

}
