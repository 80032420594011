<title>Fees, IFA Taxes</title>
<app-taxes-top-menu></app-taxes-top-menu>
<app-jumbotron title="IFA Taxes - Fees" icon="/assets/images/icons/menu/40x40/fees.webp"></app-jumbotron>

<div class="container mt-40 mb-20">
  <div class="row">
      <div class="col-12">
          <h2>Fee Schedule</h2>
          
          <div>
            <table class="fee-table">
              <tbody>
                <tr>
                  <td class="fee-header">Form Name</td>
                  <td class="fee-header">IRS Est Burden (in hours)</td>
                  <td class="fee-header">Form Description</td>
                  <td class="fee-header">Proposed Fee</td>
               </tr>
               <tr>
                <td height="19" class="xl69" style="height:14.25pt">Form 1040-X</td>
                <td class="xl97" style="border-top:none;border-left:none">4.5</td>
                <td class="xl75">Amended Income Tax Return - if we prepared the return</td>
                <td class="xl87" style="border-left:none">$300 </td>
               </tr>
               <tr>
                <td>Form 1040 +1 State return</td>
                <td class="xl98" style="border-top:none;border-left:none">13</td>
                <td class="xl76" style="border-top:none">U.S. Individual Income Tax Return</td>
                <td class="xl88" style="border-top:none;border-left:none">$500 </td>
               </tr>
               <tr>
                <td height="19" class="xl71" style="height:14.25pt;border-top:none">Additional States</td>
                <td class="xl98" style="border-top:none;border-left:none">2</td>
                <td class="xl76" style="border-top:none">&nbsp;</td>
                <td class="xl88" style="border-top:none;border-left:none">$100 </td>
               </tr>
               <tr>
                <td height="19" class="xl72" style="height:14.25pt;border-top:none">Extension to File</td>
                <td class="xl98" style="border-top:none;border-left:none">2</td>
                <td class="xl76" style="border-top:none">&nbsp;</td>
                <td class="xl88" style="border-top:none;border-left:none">$150 </td>
               </tr>           
               <tr>
                <td>Form 2210</td>
                <td class="xl98" style="border-top:none;border-left:none">6</td>
                <td class="xl76" style="border-top:none">Underpayment Penalty</td>
                <td class="xl88" style="border-top:none;border-left:none">$250 </td>
               </tr>
               <tr>
                <td>Schedule A</td>
                <td class="xl98" style="border-top:none;border-left:none">5.5</td>
                <td class="xl76" style="border-top:none">Itemized Deductions</td>
                <td class="xl88" style="border-top:none;border-left:none">$100 </td>
               </tr>
               <tr>
                <td>Form 8823</td>
                <td class="xl98" style="border-top:none;border-left:none">2.5</td>
                <td class="xl76" style="border-top:none">Noncash Charitable Contributions</td>
                <td class="xl88" style="border-top:none;border-left:none">$50 </td>
               </tr>
               <tr>
                <td>Schedule B</td>
                <td class="xl98" style="border-top:none;border-left:none">1.5</td>
                <td class="xl76" style="border-top:none">Interest and Ordinary Dividends (per item)</td>
                <td class="xl89" style="border-top:none;border-left:none">&nbsp;</td>
               </tr>
               <tr>
                <td>Schedule B - By Item</td>
                <td class="xl98" style="border-top:none;border-left:none">&nbsp;</td>
                <td class="xl76" style="border-top:none">&nbsp;</td>
                <td class="xl88" style="border-top:none;border-left:none">$30 </td>
               </tr>
               <tr>
                <td>Schedule C</td>
                <td class="xl98" style="border-top:none;border-left:none">10.5</td>
                <td class="xl76" style="border-top:none">Profit or Loss from Business</td>
                <td class="xl88" style="border-top:none;border-left:none">$200 </td>
               </tr>
               <tr>
                <td>Schedule D</td>
                <td class="xl98" style="border-top:none;border-left:none">6</td>
                <td class="xl76" style="border-top:none">Capital Gains and Losses</td>
                <td class="xl88" style="border-top:none;border-left:none">$150 </td>
               </tr>
               <tr>
                <td>Form 8949</td>
                <td class="xl98" style="border-top:none;border-left:none">5</td>
                <td class="xl76" style="border-top:none">Sale and Other Disposition of Capital Assets</td>
                <td class="xl89" style="border-top:none;border-left:none">&nbsp;</td>
               </tr>
               <tr>
                <td>Form 8949 - By Item</td>
                <td class="xl98" style="border-top:none;border-left:none">&nbsp;</td>
                <td class="xl76" style="border-top:none">&nbsp;</td>
                <td class="xl88" style="border-top:none;border-left:none">$50 </td>
               </tr>
               <tr>
                <td>Schedule E - 1 Property/1 K-1</td>
                <td class="xl98" style="border-top:none;border-left:none">10</td>
                <td class="xl76" style="border-top:none">Supplemental Income and Loss</td>
                <td class="xl88" style="border-top:none;border-left:none">$200 </td>
               </tr>
               <tr>
                <td height="19" class="xl71" style="height:14.25pt;border-top:none">Additional Rental Properties</td>
                <td class="xl98" style="border-top:none;border-left:none">5</td>
                <td class="xl76" style="border-top:none">&nbsp;</td>
                <td class="xl88" style="border-top:none;border-left:none">$200 </td>
               </tr>
               <tr>
                <td height="19" class="xl71" style="height:14.25pt;border-top:none">Additional K-1s</td>
                <td class="xl98" style="border-top:none;border-left:none">2</td>
                <td class="xl76" style="border-top:none">&nbsp;</td>
                <td class="xl88" style="border-top:none;border-left:none">$125 </td>
               </tr>
               <tr>
                <td>Schedule F</td>
                <td class="xl98" style="border-top:none;border-left:none">6</td>
                <td class="xl76" style="border-top:none">Profit or Loss from Farming</td>
                <td class="xl88" style="border-top:none;border-left:none">$150 </td>
               </tr>
               <tr>
                <td>Schedule R</td>
                <td class="xl98" style="border-top:none;border-left:none">1.5</td>
                <td class="xl76" style="border-top:none">Credit for the Elderly or the Disabled</td>
                <td class="xl88" style="border-top:none;border-left:none">$100 </td>
               </tr>
               <tr>
                <td>Schedule SE</td>
                <td class="xl98" style="border-top:none;border-left:none">2</td>
                <td class="xl76" style="border-top:none">Self Employment Tax</td>
                <td class="xl88" style="border-top:none;border-left:none">$75 </td>
               </tr>
               <tr>
                <td>Form 1116</td>
                <td class="xl98" style="border-top:none;border-left:none">8</td>
                <td class="xl76" style="border-top:none">Foreign Tax Credit</td>
                <td class="xl88" style="border-top:none;border-left:none">$100 </td>
               </tr>
               <tr>
                <td>FinCEN Form 114</td>
                <td class="xl98" style="border-top:none;border-left:none">2</td>
                <td class="xl76" style="border-top:none">Report of Foreign Bank and Financial Accounts (FBAR)</td>
                <td class="xl88" style="border-top:none;border-left:none">$75 </td>
               </tr>
               <tr>
                <td height="19" class="xl71" style="height:14.25pt;border-top:none">Additional Accounts</td>
                <td class="xl98" style="border-top:none;border-left:none">1</td>
                <td class="xl76" style="border-top:none">&nbsp;</td>
                <td class="xl88" style="border-top:none;border-left:none">$75 </td>
               </tr>
               <tr>
                <td>Schedule J</td>
                <td class="xl98" style="border-top:none;border-left:none">4</td>
                <td class="xl76" style="border-top:none">Income Averaging for Farmers and Fishermen</td>
                <td class="xl88" style="border-top:none;border-left:none">$250 </td>
               </tr>
               <tr>
                <td>Form 2441</td>
                <td class="xl98" style="border-top:none;border-left:none">3</td>
                <td class="xl76" style="border-top:none">Child and Dependent Care Expenses</td>
                <td class="xl88" style="border-top:none;border-left:none">$60 </td>
               </tr>
               <tr>
                <td>Form 3800 pg 1-2</td>
                <td class="xl98" style="border-top:none;border-left:none">10</td>
                <td class="xl76" style="border-top:none">General Business Credit, pages 1 and 2</td>
                <td class="xl88" style="border-top:none;border-left:none">$200 </td>
               </tr>
               <tr>
                <td>Form 4684</td>
                <td class="xl98" style="border-top:none;border-left:none">5</td>
                <td class="xl76" style="border-top:none">Casualties and Thefts, page 1</td>
                <td class="xl88" style="border-top:none;border-left:none">$150 </td>
               </tr>
               <tr>
                <td>Form 4797</td>
                <td class="xl98" style="border-top:none;border-left:none">8.5</td>
                <td class="xl76" style="border-top:none">Sales of Business Property</td>
                <td class="xl89" style="border-top:none;border-left:none">&nbsp;</td>
               </tr>
               <tr>
                <td>Form 4797 - By Item</td>
                <td class="xl98" style="border-top:none;border-left:none">&nbsp;</td>
                <td class="xl76" style="border-top:none">&nbsp;</td>
                <td class="xl88" style="border-top:none;border-left:none">$100 </td>
               </tr>
               <tr>
                <td>Form 4972</td>
                <td class="xl98" style="border-top:none;border-left:none">1.5</td>
                <td class="xl76" style="border-top:none">Tax on Lump-Sum Distributions</td>
                <td class="xl88" style="border-top:none;border-left:none">$100 </td>
               </tr>
               <tr>
                <td>Form 5329</td>
                <td class="xl98" style="border-top:none;border-left:none">3.5</td>
                <td class="xl76" style="border-top:none">Additional Taxes on Retirement Distributions</td>
                <td class="xl88" style="border-top:none;border-left:none">$100 </td>
               </tr>
               <tr>
                <td>Form 6198</td>
                <td class="xl98" style="border-top:none;border-left:none">3.5</td>
                <td class="xl76" style="border-top:none">At-Risk Limitations</td>
                <td class="xl88" style="border-top:none;border-left:none">$130 </td>
               </tr>
               <tr>
                <td>Form 6251</td>
                <td class="xl98" style="border-top:none;border-left:none">6.5</td>
                <td class="xl76" style="border-top:none">Alternative Minimum Tax</td>
                <td class="xl88" style="border-top:none;border-left:none">$100 </td>
               </tr>
               <tr>
                <td>Form 8615</td>
                <td class="xl98" style="border-top:none;border-left:none">2</td>
                <td class="xl76" style="border-top:none">Tax for Certain Children Who Have Unearned Income</td>
                <td class="xl88" style="border-top:none;border-left:none">$75 </td>
               </tr>
               <tr>
                <td>Form 2555 pg 1-2</td>
                <td class="xl98" style="border-top:none;border-left:none">6.5</td>
                <td class="xl76" style="border-top:none">Foreign Earned Income Exclusion, pages 1 and 2</td>
                <td class="xl88" style="border-top:none;border-left:none">$250 </td>
               </tr>
               <tr>
                <td>Form 8814</td>
                <td class="xl98" style="border-top:none;border-left:none">1.5</td>
                <td class="xl76" style="border-top:none">Parents' Election to Report Child's Int and Div</td>
                <td class="xl88" style="border-top:none;border-left:none">$75 </td>
               </tr>
               <tr>
                <td>Schedule EIC</td>
                <td class="xl98" style="border-top:none;border-left:none">1</td>
                <td class="xl76" style="border-top:none">Earned Income Credit</td>
                <td class="xl88" style="border-top:none;border-left:none">$100 </td>
               </tr>
               <tr>
                <td>Form 8862</td>
                <td class="xl98" style="border-top:none;border-left:none">1.5</td>
                <td class="xl76" style="border-top:none">Info to Claim Certain Credits After Disallowance</td>
                <td class="xl88" style="border-top:none;border-left:none">$75 </td>
               </tr>
               <tr>
                <td>Schedule H</td>
                <td class="xl98" style="border-top:none;border-left:none">2.5</td>
                <td class="xl76" style="border-top:none">Household Employment Taxes</td>
                <td class="xl88" style="border-top:none;border-left:none">$150 </td>
               </tr>
               <tr>
                <td>Schedule 8812</td>
                <td class="xl98" style="border-top:none;border-left:none">1.5</td>
                <td class="xl76" style="border-top:none">Qualifying Children and Other Dependents Credit</td>
                <td class="xl88" style="border-top:none;border-left:none">$100 </td>
               </tr>
               <tr>
                <td>Form 8606</td>
                <td class="xl98" style="border-top:none;border-left:none">2</td>
                <td class="xl76" style="border-top:none">Nondeductible IRAs</td>
                <td class="xl88" style="border-top:none;border-left:none">$50 </td>
               </tr>
               <tr>
                <td>Form 8863</td>
                <td class="xl98" style="border-top:none;border-left:none">3</td>
                <td class="xl76" style="border-top:none">Education Credits, page 1</td>
                <td class="xl88" style="border-top:none;border-left:none">$100 </td>
               </tr>
               <tr>
                <td>Form 4952</td>
                <td class="xl98" style="border-top:none;border-left:none">2</td>
                <td class="xl76" style="border-top:none">Investment Interest Expense Deduction</td>
                <td class="xl88" style="border-top:none;border-left:none">$100 </td>
               </tr>
               <tr>
                <td>Form 8889</td>
                <td class="xl98" style="border-top:none;border-left:none">3</td>
                <td class="xl76" style="border-top:none">Health Savings Accounts</td>
                <td class="xl88" style="border-top:none;border-left:none">$75 </td>
               </tr>
               <tr>
                <td>Form 8880</td>
                <td class="xl98" style="border-top:none;border-left:none">1.25</td>
                <td class="xl76" style="border-top:none">Credit for Retirement Savings Contributions</td>
                <td class="xl88" style="border-top:none;border-left:none">$50 </td>
               </tr>
               <tr>
                <td>Form 8995</td>
                <td class="xl98" style="border-top:none;border-left:none">2</td>
                <td class="xl76" style="border-top:none">Qualified Business Income Deduction - Simple</td>
                <td class="xl88" style="border-top:none;border-left:none">$100 </td>
               </tr>
               <tr>
                <td>Form 8888</td>
                <td class="xl98" style="border-top:none;border-left:none">0.5</td>
                <td class="xl76" style="border-top:none">Direct Deposit of Refund</td>
                <td class="xl88" style="border-top:none;border-left:none">$25 </td>
               </tr>
               <tr>
                <td>Form 5405</td>
                <td class="xl98" style="border-top:none;border-left:none">2</td>
                <td class="xl76" style="border-top:none">First-Time Homebuyer Credit</td>
                <td class="xl88" style="border-top:none;border-left:none">$75 </td>
               </tr>
               <tr>
                <td>Form 8958</td>
                <td class="xl98" style="border-top:none;border-left:none">1</td>
                <td class="xl76" style="border-top:none">MFS Community Property Allocation</td>
                <td class="xl88" style="border-top:none;border-left:none">$50 </td>
               </tr>
               <tr>
                <td>Form 461</td>
                <td class="xl98" style="border-top:none;border-left:none">2.5</td>
                <td class="xl76" style="border-top:none">Limitation on Business Losses</td>
                <td class="xl88" style="border-top:none;border-left:none">$100 </td>
               </tr>
               <tr>
                <td>Form 8828</td>
                <td class="xl98" style="border-top:none;border-left:none">1.5</td>
                <td class="xl76" style="border-top:none">Recapture of Federal Mortgage Subsidy</td>
                <td class="xl88" style="border-top:none;border-left:none">$75 </td>
               </tr>
               <tr>
                <td>Form 8941</td>
                <td class="xl98" style="border-top:none;border-left:none">2.5</td>
                <td class="xl76" style="border-top:none">Small Employer Health Insurance Premium Cr</td>
                <td class="xl88" style="border-top:none;border-left:none">$125 </td>
               </tr>
               <tr>
                <td>Form 6252</td>
                <td class="xl98" style="border-top:none;border-left:none">3</td>
                <td class="xl76" style="border-top:none">Installment Sale Income</td>
                <td class="xl88" style="border-top:none;border-left:none">$125 </td>
               </tr>
               <tr>
                <td>Form 8621</td>
                <td class="xl98" style="border-top:none;border-left:none">6</td>
                <td class="xl76" style="border-top:none">PFIC or QEF Informational Return, page 1</td>
                <td class="xl88" style="border-top:none;border-left:none">$300 </td>
               </tr>
               <tr>
                <td>Form 8936</td>
                <td class="xl98" style="border-top:none;border-left:none">2</td>
                <td class="xl76" style="border-top:none">Clean Vehicle Credits</td>
                <td class="xl88" style="border-top:none;border-left:none">$100 </td>
               </tr>
               <tr>
                <td>Form 8959</td>
                <td class="xl98" style="border-top:none;border-left:none">1.5</td>
                <td class="xl76" style="border-top:none">Additional Medicare Tax</td>
                <td class="xl88" style="border-top:none;border-left:none">$75 </td>
               </tr>
               <tr>
                <td>Form 8960</td>
                <td class="xl98" style="border-top:none;border-left:none">3.5</td>
                <td class="xl76" style="border-top:none">Net Investment Income Tax</td>
                <td class="xl88" style="border-top:none;border-left:none">$125 </td>
               </tr>
               <tr>
                <td>Form 8582</td>
                <td class="xl98" style="border-top:none;border-left:none">4</td>
                <td class="xl76" style="border-top:none">Passive Activity Loss Limitations</td>
                <td class="xl88" style="border-top:none;border-left:none">$175 </td>
               </tr>
               <tr>
                <td>Form 8962</td>
                <td class="xl98" style="border-top:none;border-left:none">3.5</td>
                <td class="xl76" style="border-top:none">Premium Tax Credit, page 1</td>
                <td class="xl88" style="border-top:none;border-left:none">$150 </td>
               </tr>
               <tr>
                <td>Form 5695</td>
                <td class="xl98" style="border-top:none;border-left:none">2</td>
                <td class="xl76" style="border-top:none">Residential Energy Credits</td>
                <td class="xl88" style="border-top:none;border-left:none">$125 </td>
               </tr>
               <tr>
                <td>Form 8801</td>
                <td class="xl99" style="border-top:none;border-left:none">7</td>
                <td class="xl77" style="border-top:none">Credit for Prior Year Minimum Tax</td>
                <td class="xl90" style="border-top:none;border-left:none">$300 </td>
               </tr>              
              </tbody>
            </table>

            <table class="fee-table mt-40">
              <tbody>
                <tr>
                  <td class="fee-header">Form Name</td>
                  <td class="fee-header">IRS Est Burden (in hours)</td>
                  <td class="fee-header">Form Description</td>
                  <td class="fee-header" style="min-width: 160px;">Minimum Proposed Fee</td>
                 </tr><tr>
                  <td height="19" class="xl75" style="height:14.25pt;border-top:none">Form 1065</td>
                  <td class="xl104" style="border-top:none">60</td>
                  <td class="xl93">Return of Partnership Income</td>
                  <td rowspan="8" class="xl110" width="68" style="border-bottom:1.0pt solid black; border-top:none;width:51pt">&lt;$250,000 start at $1,000<br>&gt;250,000 start at $2,500</td>
                 </tr>
                 <tr>
                  <td height="19" class="xl76" style="height:14.25pt;border-top:none">Form 1120-S</td>
                  <td class="xl105" style="border-top:none">65</td>
                  <td class="xl94" style="border-top:none">U.S. Corporation Income Tax Return</td>
                 </tr>
                 <tr>
                  <td height="19" class="xl79" style="height:14.25pt;border-top:none">Form 1120</td>
                  <td class="xl105" style="border-top:none">105</td>
                  <td class="xl94" style="border-top:none">U.S. Income Tax Return for an S Corporation</td>
                 </tr>
                 <tr>
                  <td height="19" class="xl79" style="height:14.25pt;border-top:none">Form 1041</td>
                  <td class="xl105" style="border-top:none">78</td>
                  <td class="xl94" style="border-top:none">U.S. Income Tax Return for Estates and Trusts</td>
                 </tr>
                 <tr>
                  <td height="19" class="xl79" style="height:14.25pt;border-top:none">Form 990</td>
                  <td class="xl105" style="border-top:none">107</td>
                  <td class="xl94" style="border-top:none">Return of Organization Exempt From Income Tax</td>
                 </tr>
                 <tr>
                  <td height="19" class="xl80" style="height:14.25pt;border-top:none">Form 990-EZ</td>
                  <td class="xl105" style="border-top:none">69</td>
                  <td class="xl94" style="border-top:none">Return of Organization Exempt From Income Tax - Short Form</td>
                 </tr>
                 <tr>
                  <td height="19" class="xl80" style="height:14.25pt;border-top:none">Form 990-PF</td>
                  <td class="xl105" style="border-top:none">53</td>
                  <td class="xl94" style="border-top:none">Return of Private Foundation</td>
                 </tr>
                 <tr>
                  <td height="20" class="xl81" style="height:14.65pt;border-top:none">Form 709</td>
                  <td class="xl106" style="border-top:none">6.5</td>
                  <td class="xl95" style="border-top:none">United States Gift (and Generation-Skipping Transfer) Tax Return</td>
                 </tr>
              </tbody>
            </table>
          </div>
      </div>
  </div>

  <div class="row">
    <div class="col-12 mt-80">
        <h2><a href="https://www.ifa.com/pdfs/irs-taxpayer-compliance-burden.pdf" target="_blank">Internal Revenue Service Publication 5743 (Rev. 4-2023)<fa-icon class="mr-5 pl-8" [icon]="['fas','download']"></fa-icon><i class="fa fa-download ml-5"></i></a></h2>
        
        <div>
          <iframe id="institutionalbrochure" width="100%" height="800" src="https://www.ifa.com/pdfs/irs-taxpayer-compliance-burden.pdf" frameborder="0" allowfullscreen=""></iframe>
        </div>
    </div>
</div>
</div>