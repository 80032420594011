import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs'; 
import { DataServiceService } from '../../../services/data/data-service.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-taxproposal',
  templateUrl: './taxproposal.component.html',
  styleUrls: ['./taxproposal.component.scss'],
  standalone:false
})
export class TaxproposalComponent implements OnInit, OnDestroy
{
  subp: Subscription = new Subscription();
  public proposalFormMessage = "";
  public isSending = false;
  public fgp!: FormGroup;
  constructor(private fb: FormBuilder, private ds: DataServiceService, @Inject(PLATFORM_ID) private platformId: Object) { }
  ngOnInit(): void 
  {
    this.fgp = this.fb.group
                (
                  {
                    name: [this.getWithExpiryFromSession("tpname"), [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
                    email: [this.getWithExpiryFromSession("tpemail"), [Validators.required, Validators.email]],
                    phone: [''],
                    businessTaxReturn: [''],
                    individualTaxReturn: [''],
                    yearEndPayroll: [''],
                    bookkeeping: [''],
                    taxPlanningAdvice: [''],
                    message: [''],
                    newsletter: ['']
                  }
                )
  }

  public ngOnDestroy(): void 
  {
    this.subp.unsubscribe();  
  }

  proposalFormSubmit()
  {
    this.isSending = true;
    var proposalSubmission = 
    {
      to: "lilly@ifa.com",
      to_name: "Lisa Rimke",
      from: "NoReply@ifa.com",
      from_name: this.fgp.controls['name'].value,
      reply_to: this.fgp.controls['email'].value,
      reply_to_name: this.fgp.controls['name'].value,
      subject: "IFA Proposal Request",
      dynamic_template_data: 
      {
        "message": this.fgp.controls['message'].value,
        "name": this.fgp.controls['name'].value,
        "email": this.fgp.controls['email'].value,
        "phone": this.fgp.controls['phone'].value,
        "subject": "IFA Proposal Request",
        "businessTaxReturn": this.fgp.controls['businessTaxReturn'].value,
        "individualTaxReturn": this.fgp.controls['individualTaxReturn'].value,
        "yearEndPayroll": this.fgp.controls['yearEndPayroll'].value,
        "bookkeeping": this.fgp.controls['bookkeeping'].value,
        "taxPlanningAdvice": this.fgp.controls['taxPlanningAdvice'].value,
        "newsletter": this.fgp.controls['newsletter'].value,
      },
      template_id: "d-d11a1e1dda044e37878c07a4ee8d956d"
    };

    this.subp = this.ds.postTaxContact(proposalSubmission)
                    .subscribe
                    (
                      {
                        next: (good) => { this.isSending = false; this.proposalFormMessage = "Your tax proposal message was sent successfully"},
                        error: (bad) => { alert("Something unexpected occured, please retry later.")}
                      }
                    );
  }

  private getWithExpiryFromSession(key:string):string 
  {
    if (isPlatformBrowser(this.platformId)) {  
      const itemStr = sessionStorage.getItem(key)
      if (!itemStr) return '';

      const item = JSON.parse(itemStr);
      const now = new Date().getTime();
      if (now > new Date(item.exp).getTime()) 
      {
        sessionStorage.removeItem(key);
        return '';
      }
      else
        return item.val.toString();
    }
    else{
      return '';
    }
  }
}
