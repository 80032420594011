import { Component, OnInit, ElementRef, OnDestroy, ChangeDetectorRef, Inject, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataServiceService } from '../../services/data/data-service.service';
import { Data2Service } from 'src/app/services/data2/data2.service';
import { catchError, interval, of, Subscription } from 'rxjs';
import { Article } from 'src/app/models/article';
import { Video } from 'src/app/models/video';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { HeadService } from 'src/app/services/head.service';
@Component({
  selector: 'app-taxes',
  templateUrl: './taxes.component.html',
  styleUrls: ['./taxes.component.scss'],
  standalone:false
})
export class TaxesComponent implements OnInit, OnDestroy
{
  currentYear = new Date().getFullYear();
  domain = "";
  public articles?: Article[];
  public videos?: Video[];
  sub: Subscription = new Subscription();
  suba: Subscription = new Subscription();
  subp: Subscription = new Subscription();
  subv: Subscription = new Subscription();
  public subToken: Subscription = new Subscription();
  public isSending = false;
  public contactFormMessage = "";
  public captchaSiteKey = environment.googleReCaptchaKey;
  public captchaToken:any = null;
  formToken$ = null;

  public fgtax: FormGroup;
  public fgc: FormGroup;
  constructor(public fb: FormBuilder, 
    public r: Router, 
    public ar: ActivatedRoute, 
    public ds: DataServiceService, 
    private cdr:ChangeDetectorRef, 
    private elementRef:ElementRef, 
    private data2Service: Data2Service,
    @Inject(PLATFORM_ID) private platformId: Object,
    private head:HeadService
  ) 
  { 
    this.head.setTitle("Tax Returns, Tax Planning, Tax Advice, Bookkeeping");
    this.head.setKeywords("IFA Taxes");
    this.head.setDescription("IFA Tax Returns, Tax Planning, Tax Advice, Bookkeeping");
    this.head.setMetaProperty("author","IFA Taxes");
    this.head.setMetaProperty("robots","index, follow");

    this.fgtax=  this.fb.group(
      {
        proposalName: ['', Validators.required],
        proposalEmail: ['', Validators.required]
      }      
    );
   
   
    var home = this;
    this.suba = this.ds.getArticleTax("&$top=3")
      .subscribe
      (
        resp => 
        {
          resp = resp.map( x => { x.posterUrl = x.posterUrl.replace("177x100", "512x288"); return x });
          this.articles = resp;
        }
      );

    this.subv = this.ds.getVideoTax("&$top=3")
      .subscribe
      (
        resp => 
        {
          resp = resp.map( x => { x.imageUrl = x.imageUrl.replace("177x100", "512x288"); return x });
          this.videos = resp;
        }
      );

    this.fgc = this.fb.group
    (
      {
        name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
        email: ['', [Validators.required, Validators.email]],
        subject: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50)]],
        message: ['', [Validators.required, Validators.maxLength(500)]]
      }
    );

    this.subToken = this.data2Service.formToken$().pipe(catchError(err=>{return of()})).subscribe({
      next(resp:any){
        home.formToken$ = resp;
      },
      error(err:any){
        console.log(err)
        alert("This form is currently unavailable. Please try again later.");
      }})
  }

  ngOnInit() 
  {
     if (isPlatformBrowser(this.platformId)) {  
      //safe now to use window (ssr)
      this.domain = window.location.hostname.split(":")[0];
      console.log("domain:", this.domain)
      this.fgtax = this.fb.group
      ({
        proposalEmail: ['', [Validators.required, Validators.email]],
        proposalName:  ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],   
      })
    }
  }

  ToggleNavBar() 
  {
    if (isPlatformBrowser(this.platformId)) {  
      //safe now to use document (ssr)
      let element: HTMLElement = document.getElementsByClassName( 'navbar-toggler' )[ 0 ] as HTMLElement;
      if ( element.getAttribute( 'aria-expanded' ) == 'true' ) 
      {
          element.click();
      }
    }
  }

  public proposalFormSubmit() 
  {
    this.setWithExpirySession("tpname", this.fgtax.controls["proposalName"].value);
    this.setWithExpirySession("tpemail", this.fgtax.controls["proposalEmail"].value);
    this.r.navigate(["taxes/proposal"]);
  }

  public ngOnDestroy(): void 
  {
    this.suba.unsubscribe();
    this.subv.unsubscribe();  
    this.sub.unsubscribe();
    this.subp.unsubscribe();
  }

  contactFormSubmit() 
  {
    this.isSending = true;

    var proposalSubmission = 
    {
      to: "lilly@ifa.com",
      to_name: "Lisa Rimke",
      from: "NoReply@ifataxes.com",
      from_name: this.fgc.controls["name"].value,
      reply_to: this.fgc.controls["email"].value,
      reply_to_name: this.fgc.controls["name"].value,
      subject: "IFA Contact Request",
      dynamic_template_data: 
      {
        "message": this.fgc.controls["message"].value,
        "name": this.fgc.controls["name"].value,
        "email": this.fgc.controls["email"].value,
        "subject": "IFA Contact Request"
      },
      template_id: "d-f07d9f0b45174fb8bb3bbf718fcc04db"
    };

    this.subp = this.ds.postTaxContact(proposalSubmission)
                  .subscribe
                  (
                    {
                      next: (good) => { this.contactFormMessage = "Your message was sent successfully"; },
                      error: (err) => {alert("There was an error submitting the Contact info. Please try again later.");}
                    }
                  );
  }

  

  addTokenLog(event: any)
  {
    this.captchaToken = event;
    this.cdr.detectChanges();
    var callDuration = this.elementRef.nativeElement.querySelector('#time');
    this.startTimer(callDuration);      
  }

  startTimer(display:HTMLElement) 
  {
    var timer = 59;
    var minutes;
    var seconds;
    this.sub = interval(1000).subscribe(x => {
          if(this.captchaToken==null)
          {
            this.sub.unsubscribe();
            return;
          }
        minutes = Math.floor(timer / 60);
        seconds = Math.floor(timer % 60);
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        display.textContent = minutes + ":" + seconds;
        --timer;       
    });    
  }

  private setWithExpirySession(key:string, value:string) 
  {
    const plus30m = 30*60*1000;
    const now = new Date();
    now.setTime(now.getTime() + plus30m);

    const item = 
    {
      val: value,
      exp: now,
    };
    sessionStorage.setItem(key, JSON.stringify(item));
  }
}
