import { Component, ElementRef, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup,  Validators } from '@angular/forms';
import { catchError, interval, of, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Data2Service } from 'src/app/services/data2/data2.service';
@Component({
  selector: 'app-taxes-contact',
  templateUrl: './taxes-contact.component.html',
  styleUrls: ['./taxes-contact.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush,
  standalone:false
})
export class TaxesContactComponent implements OnInit, OnDestroy {

  captchaSiteKey = environment.googleReCaptchaKey;
  captchaToken:any = null;
  submitting = false;
  public fgBC: FormGroup;
  public sub: Subscription = new Subscription();
  public SubBC: Subscription = new Subscription();
  formToken$ = null;
  public subToken: Subscription = new Subscription();

  constructor(
    private cdr:ChangeDetectorRef,
    public fb: FormBuilder, 
    private elementRef:ElementRef, 
    private data2Service: Data2Service, 
    private r: Router) 
  { 
    this.fgBC = this.fb.group
    ({
      name_first: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      name_last:  ['', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      email:  ['', [Validators.required, Validators.email]],
      phone:  ['', [Validators.required]],     
      captchaToken:[null, Validators.required],  // , Validators.required
      message: ''
    });
    // this.subToken = this.data2Service.formToken$.subscribe(resp=>{
    //   this.formToken$ = resp;
    // })
    var home = this;
    this.subToken = this.data2Service.formToken$().pipe(catchError(err=>{return of()})).subscribe({
    next(resp:any){
      home.formToken$ = resp;
    },
    error(err:any){
      console.log(err)
      alert("This form is currently unavailable. Please try again later.");
    }})

  }

  ngOnInit() {}

  addTokenLog(event: any)
  {
    this.captchaToken = event;
    this.cdr.detectChanges();
    var callDuration = this.elementRef.nativeElement.querySelector('#time');
    this.startTimer(callDuration);      
  }

  startTimer(display:HTMLElement) 
  {
    var timer = 59;
    var minutes;
    var seconds;
    this.sub = interval(1000).subscribe(x => {
          if(this.captchaToken==null)
          {
            this.sub.unsubscribe();
            return;
          }
        minutes = Math.floor(timer / 60);
        seconds = Math.floor(timer % 60);
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        display.textContent = minutes + ":" + seconds;
        --timer;       
    });    
  }

  public submitTaxesContact()
  {
    this.submitting = true;
    var priorSubmit = localStorage.getItem("IFA-become-client-submitted");
    var date = new Date();    
    var date2 = new Date(priorSubmit?priorSubmit:"1/1/2000");
    var diffMs:number = ((date.getTime())-(date2.getTime()));
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    if (priorSubmit!=null && diffMins < 1)//this is ddos territory
      setTimeout(() => 
      {
        this.r.navigate(["become-client/success"]);
      }, 350);
    else
    {
      var home=this;
      this.SubBC = this.data2Service.becomeClientFormSubmit$({...this.fgBC.value,token:this.formToken$})
      .pipe(catchError(err=>{console.log("error",err); return of()})
      ).subscribe({
        next(resp){
          localStorage.setItem("IFA-become-client-submitted", date.toISOString());
          home.r.navigate(["become-client/success"]);
        },
        error(err){
          console.log(err)
          alert("There was an error submitting the form. Please try again later.");
        },
        complete(){
          home.submitting=false;
        }
      }
      );               
    }
  }

  public ngOnDestroy(): void 
  {
    this.sub.unsubscribe();
    this.subToken.unsubscribe();
    this.SubBC.unsubscribe();
  }
}
