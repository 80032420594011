<head>
    <title>Taxes Client Login Portal</title>
        <meta name="keywords" content="Charitable Giving Tax Savings Calculator, charity, tax savings calculator, charity calculator, charity tax calculator, giving tax calculator">
        <meta name="description" content="IFA Taxes Charitable Tax Savings Calculator helps you determine your potential tax savings with a tax deductable gift to charity">
        <meta name="author" content="IFA Taxes">
        <meta name="robots" content="index, follow">
</head>
<app-taxes-top-menu></app-taxes-top-menu>
<app-jumbotron title="Taxes Client Login Portal" icon="/assets/images/icons/menu/40x40/login.webp"></app-jumbotron>

<div class="container mt-20 mb-20">
    <div class="row">
        <div class="col-12">
          <div class="card text-center">
          <div class="card-header">
            <h4>Intuit Accounts Sign In</h4>
          </div>
          <div class="card-body">
            <img src="/assets/images/logo-intuit-preferred.png" width="300" height="147" alt="Intuit Logo" class="text-center align-items-center"/>
            <p class="card-text">Click login button below to sign into your Intuit account.</p>
            <a href="https://accounts.intuit.com/signin.html?redirect_url=https%3A%2F%2Fprolink.intuit.com%2Fapp%2Ftaxpayer%2Fcollaboration&offering_id=Intuit.tax.taxcollaboration.browser" target="_blank" class="btn btn-primary">Login</a>
          </div>
          <div class="card-footer text-muted">
            The above resource link will redirect you outside of the IFATaxes domain into a third-party hosted website. Neither IFATaxes nor any of its subsidiaries or affiliates, make any representations as to the quality or accuracy of the information that is provided. IFATaxes is not liable for any direct or indirect technical or systems issues or any consequences arising out of your access to, or your use of, third-party technologies, sites, information and programs made available through the IFATaxes website.
          </div>
        </div>
        </div>
    </div>
</div>