<head>
    <title>Tax Preparation Checklist</title>
    <meta name="keywords" content="tax return checklist, tax preplist, tax checklist, business return tax checklist">
    <meta name="description" content="A tax return checklist for both individual and business tax returns.">
    <meta name="author" content="IFA Taxes">
    <meta name="robots" content="index, follow">
</head>

<app-taxes-top-menu></app-taxes-top-menu>
<app-jumbotron title="Tax Prep Checklist"></app-jumbotron>

<div class="container mt-20">
    <div class="row">
        <div class="col col-lg-12">
            <a routerLink="/taxes/login" class="btn btn-primary btn-sm float-right" role="button" aria-pressed="true" style="color: white">Login</a>
        </div>
    </div>
</div>

<div class="container mb">
    <p>Here is a list of items you will need to gather together in order to complete a tax return.</p>
    <h2>Files for Download</h2> 
    <hr />
    <div class="row">
        <div class="col col-lg-1"></div>
        <div class="col col-lg-2"><a href="https://www.ifa.com/pdfs/tax-organizer-form.pdf" target="_blank"><img src="assets/images/icons/pdf-icon.jpg" width="144" height="144" class="mx-auto d-block text-center" alt="Tax Organizer for Income" /><br /><p class="text-center">Tax Organizer Form</p></a></div>
        <div class="col col-lg-2"><a href="https://www.ifa.com/pdfs/schedulec-form-editable.pdf" target="_blank"><img src="assets/images/icons/pdf-icon.jpg" width="144" height="144" class="mx-auto d-block text-center" alt="Schedule C Business Use Home Worksheet" /><br /><p class="text-center">Schedule C Business Use Home Worksheet</p></a></div>
        <div class="col col-lg-2"><a href="https://www.ifa.com/pdfs/sch-c-worksheet-example.pdf" target="_blank"><img src="assets/images/icons/pdf-icon.jpg" width="144" height="144" class="mx-auto d-block text-center" alt="Schedule C Worksheet Example" /><br /><p class="text-center">Schedule C Worksheet Example</p></a></div>
        <div class="col col-lg-2"><a href="https://www.ifa.com/pdfs/schedulee-form-editable.pdf" target="_blank"><img src="assets/images/icons/pdf-icon.jpg" width="144" height="144" class="mx-auto d-block text-center" alt="Schedule E Worksheet" /><br /><p class="text-center">Schedule E Worksheet</p></a></div>
        <div class="col col-lg-2"><a href="https://www.ifa.com/pdfs/sch-e-worksheet-example.pdf" target="_blank"><img src="assets/images/icons/pdf-icon.jpg" width="144" height="144" class="mx-auto d-block text-center" alt="Schedule E Worksheet Example" /><br /><p class="text-center">Schedule E Worksheet Example</p></a></div>
        <div class="col col-lg-1"></div>
    </div>
    <div class="clearfix mt-5 mb-5"></div>
    <div class="row">
        <div class="col-12">

            <h2>For Individuals</h2>
            <hr />
            <h3 class="mt-5">Personal Information</h3>
            <ul>
                <li>Last year's taxes, both your federal and — if applicable — state return.</li>
                <li>Social Security numbers for yourself, your spouse and all dependents. (Dependents can also include elderly parents)</li>
            </ul>

            <h3>Income</h3>
            <p>Collect any and all documents that show earned money during the previous year. </p>

            <ul>
                <li><strong>W2 Forms</strong>. All W2 forms must be issued by January 31 from employers. They can be in both physical and electronic versions.</li>
                <li><strong>1099 Forms</strong>. (Includes 1099 from financial institutions, <a routerLink="/taxes/calculators/socialsecuritytaxable">social security</a>, and pensions)</li>

                <li><a href="https://www.schwab.com/public/schwab/investing/retirement_and_planning/taxes/resources/where-are-my-tax-forms" target="_blank" rel="noreferrer"><strong>Charles Schwab Tax Forms</strong></a>. Dates Charles Schwab tax forms are available.</li>
            </ul>

            <h3>Deductions</h3>
            <p>Starting in 2018 the standard deduction for individuals is $12,000, and $24,000 if your married filing jointly. If your itemized deductions are less than the $12,000 or $24,000 if filing jointly, it's not worth documenting your itemized deductions. If it is more then keep all of your itemized deductions. Deductions help reduce your taxable income, which generally means a lower tax bill. </p>

            <p>Here's a rundown of some popular tax deductions. Make sure you have documentation for each before you file:</p>
            <ul>
                <li><strong>Retirement Account Contributions</strong>. You can deduct contributions to a traditional IRA or self employed retirment account.</li>
                <li><strong>Educational Expenses</strong>. Students can claim deductions for tuition and fees, as well as interest paid on student loans. You need to fill out a Form 1098-T for your tuition and fees, and a Form 1098-E for your student loan.</li>
                <li><strong>Medical Bills</strong>. Have to be more than 7.5% of your adjusted gross income in order to claim a deduction.</li>
                <li><strong>Property Taxes and Mortgage Interest</strong>. Your lender should send you a Form 1098 that will include the amount of loan interest you can claim as a deduction.</li>
                <li><strong>Charitable Donations</strong>. You can deduct <a routerLink="/taxes/calculators/charitabletaxsavings">charitable contributions</a> up to 50% of your adjusted gross income. </li>
                <li><strong>Classroom Expenses.</strong> If your a school teacher or educator tou can deduct up to $250 spent on classromm supplies.</li>
            </ul>

            <p>Estimate your tax bill or refund with our free <a routerLink="/taxes/calculators/tax1040">tax calculator</a>.</p>

            <h3>Credits</h3>
            <ul>
                <li><strong>Child Credit</strong>. For 2018 the standard child credit goes from $1,000 per child to $2,000.</li>
                <li><strong>Retirement Savings Contributions Credit</strong>. This is an employer sponsored plan similar to a 401(k). If you contributed to it you may be eligible for a tax credit.</li>
                <li><strong>Premium Tax Credit</strong>. This applies only if you bought an Affordable Care Act coverage through the governments marketplace. Look for a Form 1095-A in the mail.</li>
            </ul>

            <div class="clearfix mt-5 mb-5"></div>

            <h2>For Businesses</h2>
            <hr />

            <h3 class="mt-5">Income</h3>
            <ul>
                <li>Gross receipts from sales or services</li>
                <li>Sales records</li>
                <li>Returns and allowances</li>
                <li>Business checking/savings account interest (1099-INT or statement)</li>
                <li>Other income</li>
            </ul>

            <h3>Cost of Goods Sold</h3>
            <ul>
                <li>Inventory</li>
                <li>Total dollar amount of beginning inventory</li>
                <li>Inventory purchases</li>
                <li>Total dollar amount of ending inventory</li>
                <li>Items removed</li>
                <li>Materials and Supplies</li>
            </ul>

            <h3>Expenses</h3>
            <ul>
                <li>Advertising</li>
                <li>Phones (landline, fax or cell phones related to business)
                <li>Computer & internet expenses
                <li>Transportation and travel expenses
                <ul>
                    <li><strong>Local transportation</strong>
                        <ul>
                            <li>Business trip (mileage) log</li>
                            <li>Contemporaneous log or receipts for public transportation, parking, and tolls</li>
                        </ul>
                    </li>
                </ul>
                <ul>
                    <li><strong>Travel away from home</strong>
                        <ul>
                            <li>Airfare or mileage/actual expense if drove</li>
                            <li>Hotel</li>
                            <li>Meals, tips</li>
                            <li>Taxi, Uber, tips</li>
                            <li>Internet connection (hotel, Internet café etc.)</li>
                            <li>Other</li>
                        </ul>
                    </li>
                </ul>
                    <ul>
                        <li><strong>Commissions paid to subcontractors</strong>
                            <ul>
                                <li>File Form 1099-MISC and 1096 as necessary</li>
                            </ul>
                        </li>
                    </ul>
                    <ul>
                        <li><strong>Depreciation</strong>
                            <ul>
                                <li>Cost and first date of business use of assets</li>
                                <li>Records relating to personal use of assets</li>
                                <li>Sales price and disposition date of any assets sold</li>
                            </ul>
                        </li>
                    </ul>
                    <ul>
                        <li><strong>Business insurance</strong>
                            <ul>
                                <li>Casualty loss insurance</li>
                                <li>Errors and omissions</li>
                                <li>Other</li>
                            </ul>
                        </li>
                    </ul>
                    <ul>
                        <li><strong>Interest expense</strong>
                            <ul>
                                <li>Mortgage interest on building owned by business</li>
                                <li>Business loan interest</li>
                                <li>Investment expense and interest</li>
                            </ul>
                        </li>
                    </ul>
                    <ul>
                        <li><strong>Professional fees</strong>
                            <ul>
                                <li>Lawyers, accountants, and consultants</li>
                            </ul>
                        </li>
                    </ul>
                    <ul>
                        <li><strong>Office supplies</strong>
                            <ul>
                                <li>Pens, paper, staples, glue, stickers, and other consumables, etc.</li>
                            </ul>
                        </li>
                    </ul>
                    <ul>
                        <li><strong>Rent expense</strong>
                            <ul>
                                <li>Office space rent</li>
                                <li>Business-use vehicle lease expense</li>
                                <li>Other</li>
                            </ul>
                        </li>
                    </ul>
                    <ul>
                        <li><strong>In-Home Office</strong>
                            <ul>
                                <li>Square footage of office space</li>
                                <li>Total square footage of home</li>
                                <li>Hours of use, if operating an in home daycare</li>
                                <li>Mortgage interest or rent paid</li>
                                <li>Homeowner's or renters' insurance</li>
                                <li>Utilities</li>
                                <li>Cost of home, separate improvements and first date of business use</li>
                            </ul>
                        </li>
                    </ul>
                    <ul>
                    <li><strong>Wages paid to employees</strong>
                        <ul>
                            <li>Form W-2 and W-3</li>
                            <li>Federal and state payroll returns (Form 940, Form 941, etc.)
                                <ul>
                                    <li>Employee benefit expenses</li>
                                    <li>Contractors</li>
                                    <li>Form 1099-MISc</li>
                                    <li>Form 1096</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                    <ul>
                    <li><strong>Other expenses</strong>
                        <ul>
                            <li>Repairs, maintenance of office facility, computers, etc.</li>
                            <li>Estimated tax payments made</li>
                            <li>Other business related expenses
                                <ul>
                                    <li>Health insurance</li>
                                    <li>Premiums paid to cover the sole-proprietor and family</li>
                                    <li>Premiums paid on behalf of partners and S corporation shareholders</li>
                                    <li>Information on spouse's employer provided insurance</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                </li>
            </ul>
        </div>
    </div>
</div>