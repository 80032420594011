<head>
    <title>529 Plan Tax Benefit</title>
    <meta name="keywords" content="IFA Taxes charts, charts, tax charts, taxes charts">
    <meta name="description" content="A list of tax charts for maximum contributions for tax plans broken down by state.">
    <meta name="author" content="IFA Taxes">
    <meta name="robots" content="index, follow">
</head>
<app-taxes-top-menu></app-taxes-top-menu>
<app-jumbotron title="529 Plan Tax Benefit"></app-jumbotron>

<div class="container mt-40 mb-20">
    <div class="row">
        <div class="col-sm-12">
        <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">State</th>
                  <th scope="col">State Income Tax Benefit</th>
                  <th scope="col"></th>
                  <th scope="col">Notes</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Alabama</td>
                  <td>Single: $5,000</td>
                  <td>Married: $10,000</td>
                  <td></td>
                </tr>

                <tr>
                    <th scope="row">2</th>
                    <td>Alaska</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>No State Income Tax</td>
                  </tr>

                <tr>
                    <th scope="row">3</th>
                    <td>Arizona</td>
                    <td>Single: $2,000</td>
                    <td>Married: $4,000</td>
                    <td></td>
                  </tr>

                <tr>
                    <th scope="row">4</th>
                    <td>Arkansas</td>
                    <td>Single: $5,000</td>
                    <td>Married: $10,000</td>
                    <td></td>
                  </tr>

                <tr>
                    <th scope="row">5</th>
                    <td>California</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td></td>
                  </tr>

                <tr>
                    <th scope="row">6</th>
                    <td>Colorado</td>
                    <td>Dollar for Dollar</td>
                    <td>Dollar for Dollar</td>
                    <td>To the extent of their taxable income</td>
                  </tr>

                <tr>
                    <th scope="row">7</th>
                    <td>Connecticut</td>
                    <td>Single: $5,000</td>
                    <td>Married: $10,000</td>
                    <td>Five-year carry forward of excess contributions. Rollover contributions are not deductible. </td>
                  </tr>

                <tr>
                    <th scope="row">8</th>
                    <td>District of Columbia (Washington, D.C)</td>
                    <td>Single: $4,000</td>
                    <td>Married: $8,000</td>
                    <td>Five-year carry forward of excess contributions. Rollover contributions are not deductible. Only contributions made by the account owner are deductible.</td>
                  </tr>

                <tr>
                    <th scope="row">0</th>
                    <td>Delaware</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td></td>
                  </tr>

                <tr>
                    <th scope="row">10</th>
                    <td>Florida</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>No State Income Tax </td>
                  </tr>

                <tr>
                    <th scope="row">11</th>
                    <td>Georgia</td>
                    <td>Single: $4,000</td>
                    <td>Married: $8,000</td>
                    <td>Incoming rollovers from other 529 plans do not qualify as contributions eligible for the state income tax deduction.</td>
                  </tr>

                <tr>
                    <th scope="row">12</th>
                    <td>Hawaii</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td></td>
                  </tr>

                <tr>
                    <th scope="row">13</th>
                    <td>Idaho</td>
                    <td>Single: $6,000</td>
                    <td>Married: $12,000</td>
                    <td></td>
                  </tr>

                <tr>
                    <th scope="row">14</th>
                    <td>Illinois</td>
                    <td>Single: $10,000</td>
                    <td>Married: $20,000</td>
                    <td>Principal portion is eligible for rollover contributions. Employers may claim a credit against Illinois tax for 25% of matching contributions made to an employee's account in an Illinois 529 Plan, with a maximum annual credit of $500 per employee. Unused credits may be carried forward for five years.</td>
                  </tr>

                <tr>
                    <th scope="row">15</th>
                    <td>Indiana </td>
                    <td colspan="2">Up to 20% of $5,000 per year ($1,000 yearly credit)</td>
                    
                    <td>Contributions made via rollovers from another state's tuition programs are not eligible for this tax credit.</td>
                  </tr>

                <tr>
                    <th scope="row">16</th>
                    <td>Iowa</td>
                    <td>Single: $3,474</td>
                    <td>Married: $6,948</td>
                    <td>Maximum deduction increases every year with inflation. Only contributions made by the account owner are deductible. </td>
                  </tr>

                <tr>
                    <th scope="row">17</th>
                    <td>Kansas</td>
                    <td>Single: $3,000</td>
                    <td>Married: $6,000</td>
                    <td>Rollover contributions are not deductible.</td>
                  </tr>

                <tr>
                    <th scope="row">18</th>
                    <td>Kentucky</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td></td>
                  </tr>

                <tr>
                    <th scope="row">19</th>
                    <td>Louisiana</td>
                    <td>Single: $2,400</td>
                    <td>Married: $4,800</td>
                    <td>Any unused cap amount with an active account may be carried forward to increase the cap in subsequent tax years.</td>
                  </tr>

                <tr>
                    <th scope="row">20</th>
                    <td>Maine</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td></td>
                  </tr>

                <tr>
                    <th scope="row">21</th>
                    <td>Maryland</td>
                    <td>Single: $2,500</td>
                    <td>Married: $5,000</td>
                    <td>The Maryland 529 College Investment Plan has a 10-year carryforward of excess contributions. Account owners and contributors are eligible for the deduction. Rollover contributions are deductible if not previously deducted.</td>
                  </tr>

                <tr>
                    <th scope="row">22</th>
                    <td>Massachusetts</td>
                    <td>Single: $1,000</td>
                    <td>Married: $2,000</td>
                    <td></td>
                  </tr>

                <tr>
                    <th scope="row">23</th>
                    <td>Michigan</td>
                    <td>Single: $5,000</td>
                    <td>Married: $10,000</td>
                    <td>Contributions must be reduced by qualified withdrawals during the year for purposes of determining the amount that may be deducted. Rollover contributions are not deductible.</td>
                  </tr>

                <tr>
                    <th scope="row">24</th>
                    <td>Minnesota</td>
                    <td>Single: $1,500</td>
                    <td>Married: $3,000</td>
                    <td>Deduction or credit is applied to Minnesota taxpayers for contributions to any state's 529 plan depending on their income. Alternatively to the state income tax benefit, a tax credit equal to 50$ of the contributions to accounts, reduced by any withdrawals, may be claimed with a maximum credit amount of up to $500, subject to a phase-out schedule starting at a federal adjusted gross income of $75,000.</td>
                  </tr>

                <tr>
                    <th scope="row">25</th>
                    <td>Mississippi</td>
                    <td>Single: $10,000</td>
                    <td>Married: $20,000</td>
                    <td></td>
                  </tr>

                <tr>
                    <th scope="row">26</th>
                    <td>Missouri</td>
                    <td>Single: $8,000</td>
                    <td>Married: $16,000</td>
                    <td></td>
                  </tr>

                <tr>
                    <th scope="row">27</th>
                    <td>Montana</td>
                    <td>Single: $3,000</td>
                    <td>Married: $6,000</td>
                    <td>Only contributions made by the account owner or the account owners spouse, or the account owner's custodian/parent are deductible. </td>
                  </tr>

                <tr>
                    <th scope="row">28</th>
                    <td>Nebraska</td>
                    <td>Single: $10,000</td>
                    <td>Married: $10,000</td>
                    <td>Tax benefit for $5,000 for married couples filing separately</td>
                  </tr>

                <tr>
                    <th scope="row">29</th>
                    <td>Nevada </td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>No State Income Tax </td>
                  </tr>

                <tr>
                    <th scope="row">30</th>
                    <td>New Hampshire</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>No State Income Tax </td>
                  </tr>

                <tr>
                    <th scope="row">31</th>
                    <td>New Jersey</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td></td>
                  </tr>

                <tr>
                    <th scope="row">32</th>
                    <td>New Mexico</td>
                    <td>Dollar for Dollar</td>
                    <td>Dollar for Dollar</td>
                    <td>Deductible from New Mexico state income tax up to the cost of attendance at a higher education institution.</td>
                  </tr>

                <tr>
                    <th scope="row">33</th>
                    <td>New York</td>
                    <td>Single: $5,000</td>
                    <td>Married: $10,000</td>
                    <td>Only contributions made by the account owner, or if filing jointly, by the account owner's spouse, are deductible.</td>
                  </tr>

                <tr>
                    <th scope="row">34</th>
                    <td>North Carolina</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td></td>
                  </tr>

                <tr>
                    <th scope="row">35</th>
                    <td>North Dakota</td>
                    <td>Single: $5,000</td>
                    <td>Married: $10,000</td>
                    <td></td>
                  </tr>

                <tr>
                    <th scope="row">36</th>
                    <td>Ohio</td>
                    <td>Single: $4,000</td>
                    <td>Married: $4,000</td>
                    <td>Contributions, including rollover contributions, to a Ohio 529 plan of up to $4,000 per beneficiary per year (any filing status) are deductible in computing Ohio taxable income, with an unlimited carryforward of excess contributions. </td>
                  </tr>

                <tr>
                    <th scope="row">37</th>
                    <td>Oklahoma </td>
                    <td>Single: $10,000</td>
                    <td>Married: $20,000</td>
                    <td>Any contribution in excess of this amount can be deducted over the following five tax years</td>
                  </tr>

                <tr>
                    <th scope="row">38</th>
                    <td>Oregon</td>
                    <td>Single: 100% up to $150 tax credit</td>
                    <td>Married: 100% up to $300 tax credit</td>
                    <td>Amounts exceeding contribution deduction limits may roll over for up to four succeeding tax years</td>
                  </tr>

                <tr>
                    <th scope="row">39</th>
                    <td>Pennsylvania</td>
                    <td>Single: $15,000</td>
                    <td>Married: $30,000</td>
                    <td>Provided each spouse has a taxable income of at least $15,000</td>
                  </tr>

                <tr>
                    <th scope="row">40</th>
                    <td>Rhode Island</td>
                    <td>Single: $500</td>
                    <td>Married: $1,000</td>
                    <td>Unlimited carryforward of excess contributions</td>
                  </tr>

                <tr>
                    <th scope="row">41</th>
                    <td>South Carolina</td>
                    <td>Dollar for Dollar</td>
                    <td>Dollar for Dollar</td>
                    <td>This includes rollover contributions</td>
                  </tr>

                <tr>
                    <th scope="row">42</th>
                    <td>South Dakota</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>No State Income Tax </td>
                  </tr>

                <tr>
                    <th scope="row">43</th>
                    <td>Tennessee</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>No State Income Tax </td>
                  </tr>

                <tr>
                    <th scope="row">44</th>
                    <td>Texas</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>No State Income Tax </td>
                  </tr>

                <tr>
                    <th scope="row">45</th>
                    <td>Utah</td>
                    <td>Single: 4.95% up to $2,070</td>
                    <td>Married: 4.95% up to $4,140</td>
                    <td>Individuals filing single or jointly are eligible for a 4.95% credit against Utah income tax. The maximum credit in 2021 is $100 per beneficiary for single taxpayers and $200 per beneficiary for joint filers. The credit limits are increased each year for inflation, but not decreased for deflation. Contributions to an account established after a beneficiary reaches age 19 are not eligible. Contributions from a non-owner are creditable by the account owner and not by the non-owner/contributor. </td>
                  </tr>

                <tr>
                    <th scope="row">46</th>
                    <td>Vermont </td>
                    <td>Single: 10% up to $2,500</td>
                    <td>Married: 10% up to $5,000</td>
                    <td>Filers are eligible up to 10% of Vermont tax credit (up to $250 per beneficiary per individual taxpayer or $500 per beneficiary for married taxpayers filing jointly). Rollovers from other states are eligible for the tax credit, but apply only to contributions, not any earnings and you must leave the funds in the account for the remainder of the year.</td>
                  </tr>

                <tr>
                    <th scope="row">47</th>
                    <td>Virginia</td>
                    <td>Single: $4,000</td>
                    <td>Married: $4,000</td>
                    <td>Contributions up to $4,000 per account per year are deductible in computing Virginia taxable income, with an unlimited carryforward of excess contributions. Contributions are fully deductible in the year of contribution for taxpayers at least 70 years of age. Contributions from a  non-owner are deductible by the account owner and not by the non-owner/contributor. </td>
                  </tr>

                <tr>
                    <th scope="row">48</th>
                    <td>Washington</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>No State Income Tax </td>
                  </tr>

                <tr>
                    <th scope="row">49</th>
                    <td>West Virginia</td>
                    <td>Dollar for Dollar</td>
                    <td>Dollar for Dollar</td>
                    <td>Contributions are fully deductible incomputing West Virgina taxable income.</td>
                  </tr>

                <tr>
                    <th scope="row">50</th>
                    <td>Wisconsin</td>
                    <td>Single: $3,380</td>
                    <td>Married: $3,380</td>
                    <td>Contributions to a Wisconsin 529 plan of up to $3,380 per beneficiary per year (any filing status) are deductible in computing Wisconsin taxable income. The maximum annual deductible will be increased annually to reflect inflation. Contributions in excess of the maximum annual limit may be carried forward to one or more future years and deducted up to the then annual maximum deductible amount each year until all amounts invested have been deducted from Wisconsin taxable income. Incoming rollovers from other states' 529 plans are accepted. The portion that is principal or contributions may qualify for reducing Wisconsin taxable income, including carry-forward for subsequent years; the portion attributed to growth is not eligible. Amounts that received an earlier Wisconsin reduction are not eligible. Contributors do not need to be the account owner to claim the deduction. Any Wisconsin taxpayer may claim a deduction for contributions to any account. Parents no longer need to claim their child as a dependent in order to claim the deduction; however, the maximum deduction is reduced to $1,640 for a parent who is married and filing separately or who is divorced, unless the divorce judgment specified a different division of the $3,380 combined maximum.</td>
                  </tr>

                <tr>
                    <th scope="row">51</th>
                    <td>Wyoming</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                  </tr>
              </tbody>
            </table>
            </div>
          </div>
    
    <div class="clearfix mb-5"></div>
      <div class="col-sm-12">
      <div class="alert alert-light" role="alert">
          <strong>Source:</strong>  <a href="http://savingforcollege.com/" class="alert-link" target="_blank"> Savingforcollege.com 01/2024</a> <strong> Disclosures:</strong>  529 plan data is provided for informational purposes only and should not be construed as investment or tax advice. 529 plans are subject to change.  Please visit the 529 plan of your choice for additional information, details and updates. 
      </div>
    </div>
  </div>
  </div>