<app-taxes-top-menu></app-taxes-top-menu>

<app-jumbotron title="IFA Taxes Services" icon="/assets/images/icons/top-navigation/wealth-advisors.png">
</app-jumbotron>

<div class="container mt-40 mb-20">
    <a id="tax-advice"></a>
    <div class="row mb-40">
        <div class="col-lg-4 col-md-5 col-sm-12 col-12">
            <img src="https://services.ifa.com/art/images/2296_2022-9-28-16-43-42.jpg" alt="Tax advice" class="img-fluid mr-10 pt-4" style="float:right">
        </div>
        <div class="col-lg-8 col-md-7 col-sm-12 col-12">
            <h1 class="title mt-3">Tax Advice & Planning</h1>
            <div class="info info-horizontal">
                <div class="description">
                    <fa-icon class="fa-2x mr-10 pl-8" [icon]="['fas','users']"></fa-icon>
                    <h4 class="info-title">Personal & Business</h4>
                    <ul>
                        <li>Review client data from prior and current year from tax returns and financial statements (<a routerLink="/taxes/checklist">tax prep checklist</a>)</li> 
                        <li>Discuss the tax impact of current and future activity </li>
                        <li>Review available tax credits and deductions</li>
                        <!-- <li>Examine personal and business effects of the <a routerLink="/taxes/faq">Tax Cuts & Jobs Acts</a> on your tax returns, including the qualified pass through deduction, expanded bonus depreciation and Section 179 deduction, and the limitation on state and local tax deduction.</li> -->
                    </ul>
                </div>
            </div>
            
        </div>
    </div>
    
    <div class="clearfix spacer"></div>
    
    <div class="row mt-40 mb-40" id="tax-planning">
        <div class="col-lg-4 col-md-5 col-sm-12 col-12">
            <img src="https://services.ifa.com/art/images/2297_2022-9-28-16-45-54.jpg" alt="Tax Planning"  class="img-fluid mr-10 pt-4" style="float:right">
        </div>
        <div class="col-lg-8 col-md-7 col-sm-12 col-12">
            <img src="assets/images/2-badge-advanced-online-large.png" width="130" height="130" class="float-right mt-3" alt="Quickbooks Certified Pro Advisor" />
            <h1 class="title mt-3">Tax & Bookkeeping</h1>
            <div class="info info-horizontal mt-20">
                <div class="description">
                    <fa-icon class="fa-2x mr-10 pl-8" [icon]="['fas','sort-alpha-down']"></fa-icon>
                    <h4 class="info-title">Quarterly Payroll & Sales Tax Returns</h4>
                    <p>Prepare and file, your quarterly returns based on information provided. </p>
                </div>
            </div>
            
            <div class="info info-horizontal">
                <div class="description">
                    <fa-icon class="fa-2x mr-10 pl-8" [icon]="['fas','book-open']"></fa-icon>
                    <h4 class="info-title">Monthly Bookkeeping</h4>
                    <!-- <ul>
                        <li>Offer wholesale pricing option for QuickBooks Online and QuickBooks Self Service Payroll</li>
                        <li>Reconcile credit card and bank statements </li>
                        <li>Review balance sheet and income statement items for accuracy</li>
                        <li>Propose journal entries as needed</li>
                        <li>Maintain bank and credit card data feeds into your QuickBooks Desktop/Online</li>
                        <li>Issue monthly reports (balance sheet, profit and loss, AR/AP aging) and suggested improvements</li>
                    </ul> -->
                </div>
            </div>

            <div class="info info-horizontal">
                <div class="description">
                    <fa-icon class="fa-2x mr-10 pl-8" [icon]="['fas','home']"></fa-icon>
                    <h4 class="info-title">Business Personal Property Tax</h4>
                    <p>Review your business's fixed asset list for accuracy, proper additions and deletions, and assist in general correspondence with the County Assessor.</p>
                </div>
            </div>

            <div class="info info-horizontal">
                <div class="description">
                    <!-- <fa-icon class="fa-2x mr-10 pl-8" [icon]="['fas','user-shield']"></fa-icon>
                    <h4 class="info-title">Business Accounting Software</h4>
                    <ul>
                        <li>Create and configure your chart of accounts</li>
                        <li>Convert your data from your existing accounting system to bring in your historical balances, contacts, payroll, sales and payable invoices. </li>
                        <li>Setup data feeds for your entities' bank and credit accounts and create automated bank rules for easy reconciliation of your transactions.</li>
                        <li>Customize reporting templates for your business. </li>
                        <li>Review and suggest appropriate additional software to increase efficiency in your accounting and bookkeeping.</li>
                    </ul> -->
                </div>
            </div>
        </div>

    </div>


    <div class="clearfix spacer"></div>
    <a id="tax-return"></a>
    <div class="row">
        <div class="col-lg-4 col-md-5 col-sm-12 col-12">
            <div class="row">
            <div><img src="https://services.ifa.com/art/images/2299_2022-9-28-17-54-35.jpg" alt="tax returns"  class="img-fluid mr-10 pt-4" style="float:right"></div>
            <div><small style="float:right">* Including multi state/out state returns as well as non-resident income tax returns.</small></div>
            </div>
        </div>

        <div class="col-lg-8 col-md-7 col-sm-12 col-12">
            <h1 class="title mt-3">Tax Return Preparation</h1>
            <div class="info info-horizontal">
                <div class="description">
                    <fa-icon class="fa-2x mr-10 pl-8" [icon]="['far','folder']"></fa-icon>
                    <h4 class="info-title">Income Tax for Individual Returns <small>(<a routerLink="/taxes/calculators/tax1040">Form 1040</a>)</small></h4>
                    <ul>
                        <li>Including multi state/out of state and non-resident.</li>
                        <li>Ensure accuracy and compliance with IRS and state taxing authorities </li>
                        <li>Setup data feeds for your entities' bank and credit accounts and create automated bank rules for easy reconciliation of your transactions.</li>
                        <li>Review returns with clients to ensure all eligible deductions and credits are reported.</li>
                    </ul>
                </div>
            </div>
            <div class="info info-horizontal">
                <div class="description">
                    <fa-icon class="fa-2x mr-10 pl-8" [icon]="['fas','laptop']"></fa-icon>
                    <h4 class="info-title">Business Returns</h4>
                    <ul>
                        <li>Corporation (Form 1120)</li>
                        <li>S-Corporation (Form 1120S)</li>
                        <li>Partnerships, LLP's, LLC's Form (Form 1065)</li>
                        <li><a routerLink="/taxes/calculators/estateplan">Trusts/Estates</a> (Form 1041)</li>
                        <li>Non-profit tax returns (Form 990)</li>

                    </ul>
                </div>
            </div>
        </div>

    </div>

    
</div>
  