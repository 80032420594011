<head>
    <title>Charitable Giving Tax Savings Calculator</title>
        <meta name="keywords" content="Charitable Giving Tax Savings Calculator, charity, tax savings calculator, charity calculator, charity tax calculator, giving tax calculator">
        <meta name="description" content="IFA Taxes Charitable Tax Savings Calculator helps you determine your potential tax savings with a tax deductable gift to charity">
        <meta name="author" content="IFA Taxes">
        <meta name="robots" content="index, follow">
</head>
<app-taxes-top-menu></app-taxes-top-menu>
<app-jumbotron title="Charitable Tax Savings Calculator"></app-jumbotron>


<div class="container">
    <div class="row">
        <div class="col-12">

            <!--CALC_DESC--><span id='KJECalculatorDescID'>

Use this calculator to determine your potential tax savings with a tax deductible gift to charity.  The net cost of a charitable gift, especially for high incomes, may surprise you.  This calculator sorts through the tax brackets and filing options to estimate the actual tax savings of your charitable gift.

</span><!--CALC_DESC_END-->
<noscript><div class="KJECenterText"><div id="KJENoJavaScript" class="KJENoJavaScript">Javascript is required for this calculator.  If you are using Internet Explorer, you may need to select to 'Allow Blocked Content' to view this calculator.
<p>For more information about these these financial calculators please visit: <a routerLink="http://www.dinkytown.net">Financial Calculators from KJE Computer Solutions, Inc.</a></p></div></div></noscript>
<div id="KJEAllContent"></div>

            <br>
<hr class="KJEFoooterLine">
<div class="KJECenterText"><p><span class='KJEBold'>IFA Taxes, LLC.</span><br>19200 Von Karman Ave Ste 100<br>Irvine, CA</p><p class="KJEFooter">Information and interactive calculators are made available to you as self-help tools for your independent use and are not intended to provide investment advice. We cannot and do not guarantee their applicability or accuracy in regards to your individual circumstances. All examples are hypothetical and are for illustrative purposes.  We encourage you to seek personalized advice from qualified professionals regarding all personal finance issues. </p>

            </div>
    </div>
    </div>
    </div>
    