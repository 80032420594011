<app-taxes-top-menu></app-taxes-top-menu>
<!-- <app-jumbotron title="Welcome to IFA Taxes"></app-jumbotron> -->


<div class="container-fluid">
    <div class="container">
        <!-- <div class="row"> -->
            <div class="col-12 tc">
                <h1 class="mt-40">Tax Planning, Bookkeeping Services, Tax Return Preparation</h1>
            </div>

            <div class="col-12 col-lg-6" style="display: none;">
                <form [formGroup]="fgtax" id="proposal" method="GET" action="Proposal" class="x mt-40" (ngSubmit)="proposalFormSubmit()">

                    <div class="xh">
                    <h3>Start A Tax Proposal</h3>
                    <p>Please enter a valid name and email.</p>
                    </div>
                    <div class="inputs">
                      <input formControlName="proposalName" type="text" class="fc form-control" id="proposalName" name="proposalName" placeholder="Full Name" maxlength="50" /><br>
                      <input formControlName="proposalEmail" type="email" class="fc form-control" id="proposalEmail" name="proposalEmail" placeholder="E-mail" maxlength="100" />
                      <button [disabled]="!fgtax.valid" [ngClass]="(fgtax.valid) ? 'blue-button':'disable-blue-button'" class="mt-40 center-block mt-20 font-w font-20 text-center" style="width: 430px">CONTINUE</button>
                    <!--input type="submit" id="continueProposal" value="CONTINUE PROPOSAL REQUEST" />
                    <div id="result" class="text-center text-danger mt-3">Submitted</div-->
                    </div>

                </form>
            </div>

        <!-- </div> -->
    </div>
</div>

<!-- SERVICES -->
<div class="container-fluid">
    <div class="container">
      <div class="sh mb-20 mt-20">SERVICES</div>

      <div class="row cardsection">

            <div class="card text-center mb-3">
              <img class="card-img-top" src="https://services.ifa.com/art/images/2296_2022-9-28-16-43-42.webp" alt="Tax Advice by IFA Taxes">
              <div class="card-body">
                <h5 class="card-title">Tax Advice & Planning</h5>
                <p class="card-text">Planning is the key to successfully and legally reducing your tax liability. We
                  will proactively recommend tax saving strategies to maximize your after-tax income.</p>
                <a routerLink="/taxes/services" fragment="tax-advice" class="ab btn btn-primary">Tax Advice & Planning</a>
              </div>
            </div>

            <div class="card text-center mb-3">
              <img class="card-img-top" src="https://services.ifa.com/art/images/2297_2022-9-28-16-45-54.webp" alt="Tax Planning by IFA Taxes">
              <div class="card-body">
                <h5 class="card-title">Tax & Bookkeeping</h5>
                <p class="card-text">We can enhance your back office with cloud-based tax preparation, and
                  bookkeeping support.<br><br></p>
                <a routerLink="/taxes/services" fragment="tax-planning" class="btn btn-primary ab">Tax & Bookkeeping</a>
              </div>
            </div>

            <div class="card text-center mb-3">
              <img class="card-img-top" src="https://services.ifa.com/art/images/2299_2022-9-28-17-54-35.webp" alt="Tax Returns by IFA Taxes">
              <div class="card-body">
                <h5 class="card-title">Tax Return Preparation</h5>
                <p class="card-text">We help you file your tax returns on-time, prepare extensions when necessary, and
                  review your return for opportunities to lower your tax liability.</p>
                <a routerLink="/taxes/services" fragment="tax-return" class="ab btn btn-primary">Tax Return Preparation</a>
              </div>
            </div>

      </div>
    </div>
</div>

<div class="container p-0">
  <div class="row">
    <div class="col-md-4 col-12 p-0">
      <div class="sh mb-20">LATEST TOOLS</div>
          <div class="row cardsection">
          <div class="card text-center mb-3">
            <img class="card-img-top" src="https://services.ifa.com/art/images/3275_512x288_c_tax-return-fee-calculator.png" alt="Tax Return Fee Calculator thumbnail">
            <div class="card-body">
              <h5 class="card-title">Tax Return Fee Calculator</h5>
              <p class="card-text pb-4">Estimate your tax preparation fees based on specific forms or number of line items.</p>
              <a routerLink="/calculators/tax-return-fee-calculator" class="ab btn btn-primary">Fee Calculator</a>
            </div>
          </div>
        
      </div>
    </div>
    <div class="col-md-8 col-12">
      <!-- ARTICLES -->
      <div class="">
        <div class="sh mb-20">LATEST ARTICLES</div>
        <div class="d-flex cardsection">
          <div *ngFor="let article of articles" class="card text-center mb-3">
            <app-tax-home-article-card [data]="article"></app-tax-home-article-card>
          </div>
          
         
        </div>
        <div class="mx-auto text-center mt-5">
          <a routerLink="articles" class="ab btn btn-primary btn-lg mx-auto">All Tax Articles</a>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- VIDEO -->
<!-- <div class="container mb-40">
  <div class="sh mb-20">LATEST VIDEOS</div>
  <div class="row cardsection">
      <div *ngFor="let video of videos" class="card text-center mb-3">
        <app-tax-home-video-card [data]="video"></app-tax-home-video-card>
      </div>
  </div>

  <div class="mx-auto text-center mt-5">
    <a routerLink="videos" class="ab btn btn-primary btn-lg mx-auto">All Tax Videos</a>
  </div>
</div> -->

<!-- MAP -->
<div class="container pt-5 pb-5 mt-20">
  <div class="row justify-content-center">
    <div class="col-12">
      <object style="border:0; height: 450px; width: 100%;" alt="Google Map" data="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3320.4620524480183!2d-117.86001148396029!3d33.67109814516465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcdef5a1ecc92b%3A0x91a352e03786aa25!2s19200+Von+Karman+Ave+%23100%2C+Irvine%2C+CA+92612!5e0!3m2!1sen!2sus!4v1541719846880"></object>
    </div>
  </div>
</div>

<!-- CONTACT -->
<!-- <a id="contact-form"></a>
<div class="container pt-5 pb-5">
  <div class="row justify-content-center">
    <div class="offset-top">
      <div class="col-lg-12 col-md-12 col-xs-12">
        <div class="contact-block">
          <div class="section-header" style="color: black">
            <p class="btn btn-subtitle" style="cursor:default">Contact Us</p>
            <h2 class="section-title">We're Here For Your Tax Needs</h2>
            <address>
              <p><strong>Address:</strong> <br />19200 Von Karman Ave, Suite 100<br /> Irvine, CA 92612</p>
            </address>
            <p><strong>Phone:</strong> <a href="tel:888-302-0765"> (888) 302-0765</a></p>
            <p><strong>Email:</strong> <a href="mailto:christine@ifa.com?subject=Mail From Contact Page"> christine&#64;ifa.com</a>
              <a data-name="christine" data-domain="ifataxes" data-tld="com" href="#" class="cryptedmail" onclick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld" title="email us"></a>
            </p>
          </div>

          <form [formGroup]="fgc" id="contactForm" (ngSubmit)="contactFormSubmit()">
            <div id="form-messages" *ngIf="contactFormMessage.length>0" class="h3">{{contactFormMessage}}</div>
            <div class="hideform" *ngIf="!isSending || contactFormMessage.length == 0">
              <div class="row form-group">
                <div class="col-md-12 mb-20">
                    <input formControlName="name" type="text" class="fc form-control" id="name" name="name" placeholder="Your name">
                    <span *ngIf="fgc.controls['name'].invalid && (fgc.controls['name'].dirty || fgc.controls['name'].touched)" class="errorHint">Please enter your name.</span>
                </div>
                <div class="col-md-12 mb-20">
                    <input formControlName="email" type="email" placeholder="Email" id="email" class="fc form-control" name="email">
                    <span *ngIf="fgc.controls['email'].invalid && (fgc.controls['email'].dirty || fgc.controls['email'].touched)" class="errorHint">Please enter your valid email.</span>
                </div>

                
                <div class="col-md-12 mb-20">
                    <input formControlName="subject" type="text" placeholder="Subject" id="msg_subject" class="fc form-control" name="subject">
                    <span *ngIf="fgc.controls['subject'].invalid && (fgc.controls['subject'].dirty || fgc.controls['subject'].touched)" class="errorHint">Please enter a valid subject.</span>
                </div>

                
                <div class="col-md-12 mb-20">
                    <textarea formControlName ="message" class="fc form-control" id="message" placeholder="Message" rows="2" name="message" style="width:90%; margin-left:5%;"></textarea>
                    <span *ngIf="fgc.controls['message'].invalid && (fgc.controls['message'].dirty || fgc.controls['message'].touched)" class="errorHint">Please enter a detailed message.</span>
                </div>

                <div class="col-md-12 mb-20 justify-content-center">
                  <div id="recaptcha" class="align-content-md-center">
                    <re-captcha formControlName="captchaToken" (resolved)="addTokenLog($event)">
                    </re-captcha>     
                    <div>
                      <span id="time" *ngIf="captchaToken!=null" class="text-danger"></span>&nbsp;
                    </div>        
                    <span *ngIf="captchaToken==null" class="errorHint">
                      Please complete the CAPTCHA
                    </span>
                  </div>
                  
                  <div class="submit-button text-center">
                    <button type="submit" [disabled]="!fgc.valid || isSending" [ngClass]="(fgc.valid && ! isSending && captchaToken!=null) ? 'blue-button':'disable-blue-button'" class="mb-40 center-block mt-20 font-w font-20 text-center" id="submit" type="submit" style="max-width: 350px; min-width: 200px;">Submit</button>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div> -->
