<a routerLink="{{data.url}}" *ngIf="data.url; else NoBio" class="team-card">
  <!-- <span>View Profile <i class="fa fa-chevron-right"></i></span> -->
  <div class="advisor-title">
    <div class="team-title-holder">
      <h2 class="font-w font-20 mt-0 mb-2">{{data.name}} {{data.name_last}} <span class="pe-1" *ngFor="let c of data.designations" [title]="c.description">{{c.designation}}</span></h2>
      <p class="font-w font-14 mb-0 float-start width-100">{{data.description}}</p>
      <div class="float-end d-table-cell align-items-end font-w font-13 decoration-none">
        <span class="profileUrl">View Profile <fa-icon [icon]="faChevronRight" size="1x" ngClass="ms-1 me-1 mt-1 float-end d-inline"></fa-icon>
        </span>
      </div>
    </div>
  </div>
  <img src="{{data.imageUrl}}" alt="{{data.imageAltText}}" class="i">
</a>

<ng-template #NoBio>
  <div class="team-card">
    <div class="advisor-title">
      <div class="team-title-holder">
        <h2 class="font-w font-20 mt-0 mb-2">{{data.name}} {{data.name_last}} <span class="pe-1" *ngFor="let c of data.designations" [title]="c.description">{{c.designation}}</span></h2>
        <p class="font-w font-14 mb-0 float-start width-100">{{data.description}}</p>
        <p class="font-w font-14 mb-0 float-start width-100" *ngIf="plainphone">Phone: {{plainphone | mask: '000-000-0000'}}</p>
        <p class="font-w font-14 mb-0 float-start width-100" *ngIf="plainemail" (click)="openMail(plainemail)" [ngClass]="{'hand': plainemail}">Email {{data.name}} {{data.name_last}}</p>
      </div>
    </div>
    <img src="{{data.imageUrl}}" alt="{{data.imageAltText}}" class="i">
  </div>
</ng-template>