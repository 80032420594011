

<div class="card flex-md-row mb-4 box-shadow h-md-250">
    <div class="card-body d-flex flex-column align-items-start">
      <strong class="d-inline-block mb-2 text-primary">{{data.titletag}}</strong>
      <h3 class="mb-0">
        <b><a class="text-dark" routerLink="/articles/{{data.friendlyUrl}}">{{data.title}}</a></b>
      </h3>
      <div class="mb-1 text-muted"><small>{{data.date | date: "MMM d, yyyy"}}</small></div>
      <div [innerHTML]="data.teaser" class="card-text mb-auto"></div>
      <a routerLink="/articles/{{data.friendlyUrl}}">Continue reading</a>
    </div>
    <img class="card-img-right flex-auto d-none d-md-block" src="{{data.posterUrl}}" alt="" style=""/>
  </div>