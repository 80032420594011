<title>1040 Tax Calculator</title>
<meta name="description" content="IFA Taxes 1040 Tax Calculator helps you estimate your total tax as well as tax refund or the amount you owe the IRS">
<app-taxes-top-menu></app-taxes-top-menu>
<app-jumbotron title="1040 Tax Calculator"></app-jumbotron>

<div class="container">
    <div class="row">
        <div class="col-12">
            <!--CALC_DESC-->
            <span id='KJECalculatorDescID'>
            <p>Enter your filing status, income, deductions and credits and we will estimate your total taxes.  Based on your projected tax withholding for the year, we can also estimate your tax refund or amount you may owe the IRS next April.</p>
            </span><!--CALC_DESC_END-->
            <noscript><div class="KJECenterText"><div id="KJENoJavaScript" class="KJENoJavaScript">Javascript is required for this calculator.  If you are using Internet Explorer, you may need to select to 'Allow Blocked Content' to view this calculator.
            <p>For more information about these these financial calculators please visit: <a routerLink="http://www.dinkytown.net">Financial Calculators from KJE Computer Solutions, Inc.</a></p></div></div></noscript>
            <div id="KJEAllContent"></div>
            <br>
            <hr class="KJEFoooterLine">
            <div class="KJECenterText"><p><span class='KJEBold'>IFA Taxes, LLC.</span><br>19200 Von Karman Ave Ste 100<br>Irvine, CA</p><p class="KJEFooter">Information and interactive calculators are made available to you as self-help tools for your independent use and are not intended to provide investment advice. We cannot and do not guarantee their applicability or accuracy in regards to your individual circumstances. All examples are hypothetical and are for illustrative purposes.  We encourage you to seek personalized advice from qualified professionals regarding all personal finance issues. </p>
            </div>
        </div>
    </div>
</div>
  
  