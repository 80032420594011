#KJEAllContent {
    background: #ffffff;
    color: #000000;
}

.KJEFontHeading {
    color:#000000;
}

.KJEError {
    color:#BB0000;
}

.KJETitle {
}

.KJECalculatorTable {
}

.KJEReportTitle {
}

.KJEReportTitleBlock {
}

.KJEWidthConstraint {
    max-width: 900px;
    text-align:left;
    margin: auto;
    background: #ffffff;
    padding:15px;
}

.KJEWrapper {
    background: #ffffff;
    padding: 0;
    margin:0;
}

body {
 margin: 0;
}


/**CUSTOMCSS**/

table.KJEReportTable th.KJEHeading:hover {
    background-color: #1b5eab;
}

table.KJEReportTable td.KJECell:hover {
    background-color: #1b5eab;
}

table.KJEReportTable .KJELabel:hover {
    background-color: #1b5eab;
}

table.KJEReportTable caption.KJEHeaderRow:hover {
    background-color: #1b5eab;
}

.KJEInputContainer .KJEHelp:hover {
    color: #1b5eab;
}

button.KJEDefinitionIcon:hover {
    background-color: #1b5eab;
}

button.KJEDefinitionIcon, button.KJECloseButton {
    color: #1b5eab;
}

table.KJEReportTable tr.KJEOddRow:hover {
    background-color: #2f74da;
}

table.KJEReportTable thead.KJEReportTHeader tr.KJEHeaderRow:hover {
    background-color: #2f74da;
}

table.KJEReportTable tr.KJEEvenRow:hover {
    background-color: #2f74da;
}

table.KJEReportTable tr.KJEFooterRow:hover {
    background-color: #2f74da;
}

table.KJEReportTable td.KJECellStrong:hover {
    background-color: #1b5eab;
}

#KJEGuiDataPoint {
    border-color: #1b5eab;
}

table.KJEReportTable[_ngcontent-xku-c6] tr.KJEEvenRow[_ngcontent-xku-c6]:hover {
    background-color: #1b5eab;
}

table.KJEIndexTable[_ngcontent-jgw-c6] th.KJELabel[_ngcontent-jgw-c6] a[_ngcontent-jgw-c6] {
    color: #1b5eab!important;
}

table.KJEReportTable[_ngcontent-dxo-c6] caption.KJEHeaderRow[_ngcontent-dxo-c6]:hover, table.KJEScheduleTable[_ngcontent-dxo-c6] caption.KJEHeaderRow[_ngcontent-dxo-c6]:hover, table.KJEReportTable[_ngcontent-dxo-c6] th.KJEHeading[_ngcontent-dxo-c6]:hover, table.KJEReportTable[_ngcontent-dxo-c6] td.KJECell[_ngcontent-dxo-c6]:hover, table.KJEReportTable[_ngcontent-dxo-c6] td.KJECellStrong[_ngcontent-dxo-c6]:hover, table.KJEReportTable[_ngcontent-dxo-c6] .KJELabel[_ngcontent-dxo-c6]:hover, table.KJECalcTable[_ngcontent-dxo-c6] th.KJEHeading[_ngcontent-dxo-c6]:hover, table.KJECalcTable[_ngcontent-dxo-c6] td.KJECell[_ngcontent-dxo-c6]:hover, table.KJECalcTable[_ngcontent-dxo-c6] td.KJECellStrong[_ngcontent-dxo-c6]:hover, table.KJECalcTable[_ngcontent-dxo-c6] .KJELabel[_ngcontent-dxo-c6]:hover, table.KJEScheduleTable[_ngcontent-dxo-c6] th.KJEScheduleHeading[_ngcontent-dxo-c6]:hover, table.KJEScheduleTable[_ngcontent-dxo-c6] th.KJEScheduleCell[_ngcontent-dxo-c6]:hover, table.KJEScheduleTable[_ngcontent-dxo-c6] td.KJEScheduleCell[_ngcontent-dxo-c6]:hover, table.KJEScheduleTable[_ngcontent-dxo-c6] td.KJEScheduleCellStrong[_ngcontent-dxo-c6]:hover {
    color: #ffffff!important;
}

table.KJEReportTable[_ngcontent-qxy-c6] .KJELabel[_ngcontent-qxy-c6]:hover {
    color: #ffffff!important;
}

table.KJEReportTable[_ngcontent-rgc-c6] tr.KJEOddRow[_ngcontent-rgc-c6]:hover {
    color: #ffffff!important;
}

table.KJEIndexTable th.KJELabel a, #KJEAllContent a {
    color: #1b5eab;
}

table.KJEIndexTable th.KJELabel a:hover, #KJEAllContent a:hover {
    color: #ffffff;
}


