<app-taxes-top-menu></app-taxes-top-menu>
<app-jumbotron title="Tax Preparation" icon="/assets/images/icons/menu/40x40/academic-papers.webp"></app-jumbotron>

<div class="container-fluid mb-40 mt-80">
    <div class="container">

        <div class="row cardsection">

            <div class="card text-center mb-4">
                <img class="card-img-top" src="https://services.ifa.com/art/images/198_512x288_c_calculator-investments.webp" loading="lazy" alt="Tax Calculators">
                <div class="card-body">
                  <h5 class="card-title">Tax Calculators</h5>
                  <p class="card-text">A useful set of tax calculators including 1040 tax calculator, estate planning calculator, charitable giving calculator, and social security taxable benefits calculator.</p>
                  <a routerLink="/taxes/calculators" class="ab btn btn-primary">Tax Calculators</a>
                </div>
            </div>

            <div class="card text-center mb-4">
                <img class="card-img-top" src="https://services.ifa.com/art/images/1595_512x288_c_checklist.webp" loading="lazy" alt="Tax checklist">
                <div class="card-body">
                  <h5 class="card-title">Tax Checklist</h5>
                  <p class="card-text">A list of items you will need to gather together in order to complete a tax return.<br><br><br></p>
                  <a routerLink="/taxes/checklist" class="ab btn btn-primary">Tax Checklist</a>
                </div>
            </div>

            <div class="card text-center mb-4">
                <img class="card-img-top" src="https://services.ifa.com/art/images/2308_512x288_c_tax-standard-mileage-rate.webp" loading="lazy" alt="Tax Charts">
                <div class="card-body">
                  <h5 class="card-title">Tax Charts</h5>
                  <p class="card-text">Helpful tax charts including 529 Plan Tax Benefit list for all states, and standard mileage rates.<br><br></p>
                  <a routerLink="/taxes/charts" class="ab btn btn-primary">Tax Charts</a>
                </div>
            </div>

        </div>
    </div>
</div>        