import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-taxes-faq',
  templateUrl: './taxes-faq.component.html',
  styleUrls: ['./taxes-faq.component.scss'],
  standalone:false
})
export class TaxesFaqComponent implements OnInit 
{
  
  open = false;
  open1 = false;
  open2 = false;
  open3 = false;
  open4 = false;
  open5 = false;
  open6 = false;
  isOpen = false;

  constructor() { }

  show(){
    var elements = document.querySelectorAll(".accordion-collapse");
    for (let i = 0; i < elements.length; i++) {  
      elements[i].classList.add("show");  
    }  
  }

  ngOnInit(): void 
  {
  }

  toggle() 
  {
    this.open = !this.open
    this.open1 = !this.open1
    this.open2 = !this.open2
    this.open3 = !this.open3
    this.open4 = !this.open4
    this.open5 = !this.open5
    this.open6 = !this.open6
    this.isOpen = !this.isOpen
  }
  
  toggleAccordian() 
  {
    this.open = false;
    this.open = !this.open
    this.open1 = !this.open1
    this.open2 = !this.open2
    this.open3 = !this.open3
    this.open4 = !this.open4
    this.open5 = !this.open5
    this.open6 = !this.open6
    this.isOpen = false;
  }

}
