import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBookOpen, faHome, faUserShield, faLaptop, faSortAlphaDown, faUsers, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faFolder } from '@fortawesome/free-regular-svg-icons';

import { TaxesTopMenuComponent } from './_component/taxes-top-menu/taxes-top-menu.component';
import { TaxaboutComponent } from './taxabout/taxabout.component';
import { TaxesComponent } from './taxes.component'
import { TaxesFooterComponent } from './_component/taxes-footer/taxes-footer.component';
import { TaxesFaqComponent } from './taxes-faq/taxes-faq.component';
import { TaxesServicesComponent } from './taxes-services/taxes-services.component';
import { CalculatorsComponent } from './calculators/calculators.component';
import { Tax1040Component } from './calculators/tax1040/tax1040.component';
import { CharitabletaxsavingsComponent } from './calculators/charitabletaxsavings/charitabletaxsavings.component';
import { EstateplanComponent } from './calculators/estateplan/estateplan.component';
import { SocialsecuritytaxableComponent } from './calculators/socialsecuritytaxable/socialsecuritytaxable.component';
import { RetiredistribComponent } from './calculators/retiredistrib/retiredistrib.component';
import { TaxchecklistComponent } from './taxchecklist/taxchecklist.component';
import { TaxloginComponent } from './taxlogin/taxlogin.component';
import { TaxchartsComponent } from './taxcharts/taxcharts.component';
import { Chart529Component } from './taxcharts/chart529/chart529.component';
import { ChartmileageComponent } from './taxcharts/chartmileage/chartmileage.component';
import { TaxHomeArticleCardComponent } from './_component/tax-home-article-card/tax-home-article-card.component';
import { TaxarticlesComponent } from './taxarticles/taxarticles.component';
import { TaxArticleCardComponent } from './_component/tax-article-card/tax-article-card.component';
import { TaxPaginationComponent } from './_component/tax-pagination/tax-pagination.component';
import { TaxesContactComponent } from './taxes-contact/taxes-contact.component';
import { TaxesVideosComponent } from './taxes-videos/taxes-videos.component';
import { TaxesVideoComponent } from './taxes-videos/taxes-video/taxes-video.component';
import { TaxHomeVideoCardComponent } from './_component/tax-home-video-card/tax-home-video-card.component';
// import { TaxvideosComponent } from './taxvideos/taxvideos.component';
import { TaxVideoCardComponent } from './_component/tax-video-card/tax-video-card.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha-2';
import { TaxpreparationComponent } from './taxpreparation/taxpreparation.component';
import { TaxproposalComponent } from './taxproposal/taxproposal.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TaxTeamCardComponent } from './_component/tax-team-card/tax-team-card.component';
import { TaxesFeesComponent } from './taxes-fees/taxes-fees.component';
import { JumbotronComponent } from '../_components/jumbotron/jumbotron.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { DynamicScriptLoaderServiceTaxes } from './svc/dynamic-script-loader.service';


@NgModule(
  {
    declarations: 
    [
      TaxesComponent,
      TaxTeamCardComponent,
      TaxaboutComponent,
      TaxesFooterComponent,
      TaxesFaqComponent,
      TaxesServicesComponent,
      CalculatorsComponent,
      Tax1040Component,
      CharitabletaxsavingsComponent,
      EstateplanComponent,
      SocialsecuritytaxableComponent,
      RetiredistribComponent,
      TaxchecklistComponent,
      TaxloginComponent,
      TaxchartsComponent,
      Chart529Component,
      ChartmileageComponent,
      TaxHomeArticleCardComponent,
      TaxarticlesComponent,
      TaxArticleCardComponent,
      TaxPaginationComponent,
      TaxesContactComponent,
      TaxesVideosComponent,
      TaxesVideoComponent,
      TaxHomeVideoCardComponent,
      // TaxvideosComponent,
      TaxVideoCardComponent,
      TaxpreparationComponent,
      TaxproposalComponent,
      TaxesFeesComponent
    ],
    imports: 
    [
      CommonModule,
      AccordionModule.forRoot(),
      FontAwesomeModule,
      FormsModule,
      JumbotronComponent,      
      NgxMaskPipe,
      ReactiveFormsModule,
      RecaptchaModule,
      RecaptchaFormsModule, 
      TaxesTopMenuComponent,           
      RouterModule.forChild
      (
        [
          { path: '', pathMatch: 'full', component: TaxesComponent },
          { path: 'about', component: TaxaboutComponent},
          //Taxes Team LANDING PAGES HERE
          { path:   'about/christine-mealy', loadChildren: () => import('../about/person/person.module').then(m => m.PersonModule),data:{first:'Christine',last:'Mealy'}},
          { path:   'about/david-york', loadChildren: () => import('../about/person/person.module').then(m => m.PersonModule),data:{first:'David',last:'York'}},
          { path:   'about/kathleen-wetzel', loadChildren: () => import('../about/person/person.module').then(m => m.PersonModule),data:{first:'Kathleen',last:'Wetzel'}},
          { path:   'about/lisa-rimke', loadChildren: () => import('../about/person/person.module').then(m => m.PersonModule),data:{first:'Lisa',last:'Rimke'}},
          { path:   'about/ricky-martinez', loadChildren: () => import('../about/person/person.module').then(m => m.PersonModule),data:{first:'Ricky',last:'Martinez'}}, //

          {
            path: 'articles',
              children:
                [
                  { path: '', pathMatch: 'full', component: TaxarticlesComponent },
                ]
          },
          { 
            path: 'charts', 
              children:
                [
                  { path: '', pathMatch: 'full', component: TaxchartsComponent },
                  { path: 'chart529', pathMatch: 'full', component: Chart529Component},
                  { path: 'chartmileage', pathMatch: 'full', component: ChartmileageComponent},
                ]
          },
          { 
            path: 'calculators', 
              children:
                [
                  { path: '',         pathMatch:'full', component:  CalculatorsComponent },
                  { path: 'tax1040',  pathMatch:'full', component:  Tax1040Component },
                  { path: 'charitabletaxsavings',  pathMatch:'full', component:  CharitabletaxsavingsComponent },
                  { path: 'estateplan',  pathMatch:'full', component:  EstateplanComponent },
                  { path: 'socialsecuritytaxable',  pathMatch:'full', component:  SocialsecuritytaxableComponent },
                  { path: 'retiredistrib',  pathMatch:'full', component:  RetiredistribComponent },
                ]
          },

          { path: 'checklist', pathMatch: 'full', component: TaxchecklistComponent },
          { path: 'contact', pathMatch: 'full', component: TaxesContactComponent },
          { path: 'faq', pathMatch: 'full', component: TaxesFaqComponent },
          { path: 'fees', pathMatch: 'full', component: TaxesFeesComponent },
          { path: 'login', pathMatch: 'full', component: TaxloginComponent },
          { path: 'preparation', pathMatch: 'full', component: TaxpreparationComponent },
          { path: 'proposal', pathMatch: 'full', component: TaxproposalComponent },
          { path: 'services', pathMatch: 'full', component: TaxesServicesComponent },
          // { path: 'videos', pathMatch: 'full', component: TaxvideosComponent },

        ]
      )
    ],
    providers:[
      DynamicScriptLoaderServiceTaxes, 
      provideNgxMask()
    ]
  }
)
export class TaxesModule 
{
  constructor(library: FaIconLibrary) 
  {
    library.addIcons
    (
      faBookOpen,
      faFolder,
      faHome,
      faLaptop,
      faSortAlphaDown,
      faUsers,
      faUserShield,
      faChevronRight
    );
  }
}
