import { Component, OnInit } from '@angular/core';
import { HeadService } from 'src/app/services/head.service';

@Component({
  selector: 'app-taxes-services',
  templateUrl: './taxes-services.component.html',
  styleUrls: ['./taxes-services.component.scss'],
  standalone:false
})
export class TaxesServicesComponent implements OnInit {

  constructor(private head:HeadService
    ) 
    { 
      this.head.setTitle("Tax Services");
      this.head.setKeywords("IFA Taxes, Tax Services");
      this.head.setDescription("IFA Taxes offers services ranging from tax advice and planning, tax and bookkeeping, and tax return preparation");
      this.head.setMetaProperty("author","IFA Taxes");
      this.head.setMetaProperty("robots","index, follow"); }

  ngOnInit(): void {
  }

}
