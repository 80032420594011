import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";

interface Scripts {
  name: string;
  src: string;
}

export const ScriptStore: Scripts[] = [
  { name: 'kje', src: 'assets/taxcalculators/KJE.js'},          //'assets/calculator/KJE.js' },
  { name: 'kje-specific', src: 'assets/taxcalculators/KJESiteSpecific.js' },
  { name: 'tax-1040', src: 'assets/taxcalculators/Tax1040.js' },
  { name: 'tax-1040-params', src: 'assets/taxcalculators/Tax1040Params.js' },
  { name: 'social-security', src: 'assets/taxcalculators/SocialSecurityTaxable.js' },
  { name: 'social-security-params', src: 'assets/taxcalculators/SocialSecurityTaxableParams.js' },
  { name: 'estate-plan', src: 'assets/taxcalculators/EstatePlan.js' },
  { name: 'estate-plan-params', src: 'assets/taxcalculators/EstatePlanParams.js' },
  { name: 'charitable-savings', src: 'assets/taxcalculators/CharitableTaxSavings.js' },
  { name: 'charitable-savings-params', src: 'assets/taxcalculators/CharitableTaxSavingsParams.js' },
  { name: 'retirement-dist', src: 'assets/taxcalculators/RetireDistrib.js' },
  { name: 'retirement-dist-params', src: 'assets/taxcalculators/RetireDistribParams.js' }
];

declare var document: any;
@Injectable({
  providedIn: 'root'
})
export class DynamicScriptLoaderServiceTaxes {

  private scripts: any = {};

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
     
        if (!this.scripts[name].loaded) {
          //load script
          if (isPlatformBrowser(this.platformId)) { 
            //safe now to use document (ssr)
          let script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = this.scripts[name].src;
          if (script.readyState) {  //IE
              script.onreadystatechange = () => {
                  if (script.readyState === "loaded" || script.readyState === "complete") {
                      script.onreadystatechange = null;
                      this.scripts[name].loaded = true;
                      resolve({script: name, loaded: true, status: 'Loaded'});
                  }
              };
          } else {  //Others
              script.onload = () => {
                  this.scripts[name].loaded = true;
                  resolve({script: name, loaded: true, status: 'Loaded'});
              };
          }
          script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
          document.getElementsByTagName('body')[0].appendChild(script);
        }
        else{
          resolve({ script: name, loaded: false, status: 'Load Only in a Browser not SSR' });
        }
      }
      
        else {
          resolve({ script: name, loaded: true, status: 'Already Loaded' });
        }
       
    });
 
  }
}
