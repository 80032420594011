<head>
    <title>Estate Tax Planning Calculator</title>
    <meta name="keywords" content="Estate Tax Planning Calculator, estate tax calculator, estate planning calculator, tax planning calculator, estate tax plan">
    <meta name="description" content="IFA Taxes Estate Tax Planning Calculator helps you know your potential estate tax liability, and is a great place to start your estate tax plan.">
    <meta name="author" content="IFA Taxes">
    <meta name="robots" content="index, follow">
</head>

<app-taxes-top-menu></app-taxes-top-menu>
<app-jumbotron title="Estate Tax Planning Calculator"></app-jumbotron>

<div class="container">
    <div class="row">
      <div class="col-12">
        <re-captcha (resolved)="null" style="width:100%; margin:auto; display: none;">
        </re-captcha>
        <div [formGroup]="myGroup">
        <!--CALC_DESC--><span id='KJECalculatorDescID'>

          Estate tax planning is very important to preserving your wealth for future generations. Knowing your
          potential estate tax liability is a great place to start your estate tax plan. Use this calculator to project
          the value of your estate, and the associated estate tax, for the next ten years. This calculator uses the
          rules passed into law as part of the "2017 Tax Cuts and Jobs Act." <strong>Please be aware that certain
            estate planning documents, which are beyond the scope of this calculator, may be necessary in order for
            assets to be distributed according to your wishes.</strong>

        </span>
        <!--CALC_DESC_END-->
        <noscript>
          <div class="KJECenterText">
            <div id="KJENoJavaScript" class="KJENoJavaScript">Javascript is required for this calculator. If you are using
              Internet Explorer, you may need to select to 'Allow Blocked Content' to view this calculator.
              <p>For more information about these these financial calculators please visit: <a routerLink="http://www.dinkytown.net">Financial
                  Calculators from KJE Computer Solutions, Inc.</a></p>
            </div>
          </div>
        </noscript>
        <div id="KJEAllContent"></div>

        <br>
        <hr class="KJEFoooterLine">
        <div class="KJECenterText">
          <p><span class='KJEBold'>IFA Taxes, LLC.</span><br>19200 Von Karman Ave Ste 100<br>Irvine, CA</p>
          <p class="KJEFooter">Information and interactive calculators are made available to you as self-help tools for
            your independent use and are not intended to provide investment advice. We cannot and do not guarantee
            their applicability or accuracy in regards to your individual circumstances. All examples are hypothetical
            and are for illustrative purposes. We encourage you to seek personalized advice from qualified
            professionals regarding all personal finance issues. </p>

        </div>
      </div>
      </div>
    </div>
</div>