import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chart529',
  templateUrl: './chart529.component.html',
  styleUrls: ['./chart529.component.scss'],
  standalone:false
})
export class Chart529Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
