<head>
    <title>Request A Tax Proposal</title>
    <meta name="keywords" content="Tax Proposal, request tax proposal, tax advice, tax season, irvine tax proposal">
    <meta name="description" content="Request a proposal from IFA Taxes for business tax returns, individual tax returns, payroll, bookkeeping and tax planning advice.">
    <meta name="author" content="IFA Taxes">
    <meta name="robots" content="index, follow">
</head>
<app-taxes-top-menu></app-taxes-top-menu>

<div class="container">
    <div class="row col-12 mt-40">

        <form [formGroup]="fgp" (ngSubmit)="proposalFormSubmit()">
        <div id="form-messages" *ngIf="proposalFormMessage.length > 0" class="h3 mt-80 mb-80"><b>{{proposalFormMessage}}</b></div>
        <div class="hideform" *ngIf="!isSending && proposalFormMessage.length == 0">
            <div class="header mb-20">
                <h3><b>Finish Tax Proposal</b></h3>
            </div>

            <div class="inputs">

                <input formControlName="name"  type="text" id="proposalName" name="name" placeholder="Name" class="x fc form-control" />
                <span *ngIf="fgp.controls['name'].invalid && (fgp.controls['name'].dirty || fgp.controls['name'].touched)" class="errorHint">Please enter your name.</span>

                <input formControlName="email" type="email" id="proposalEmail" name="email" placeholder="E-mail"  class="x fc form-control"/>
                <span *ngIf="fgp.controls['email'].invalid && (fgp.controls['email'].dirty || fgp.controls['email'].touched)" class="errorHint">Please enter your valid email.</span>

                <input formControlName="phone" type="tel" id="phone" name="phone" placeholder="Phone" class="x fc form-control" mask="000000099999999"/>
                
                <p><small>Select All That Apply</small></p>
                <div class="checkbox">
                    <input name="businessTaxReturn" formControlName="businessTaxReturn" type="checkbox" id="businessTaxReturn"/><label class="terms" for="businessTaxReturn">Business Tax Return</label>
                </div>

                <div class="checkbox">
                    <input name="individualTaxReturn" formControlName="individualTaxReturn" id="individualTaxReturn" type="checkbox"/><label class="terms" for="individualTaxReturn">Individual Tax Return</label>
                </div>
                <div class="checkbox">
                    <input name="yearEndPayroll" formControlName="yearEndPayroll" id="yearEndPayroll" type="checkbox"/><label class="terms" for="yearEndPayroll">Year End Payroll</label>
                </div>
                <div class="checkbox">
                    <input name="bookkeeping" formControlName="bookkeeping" id="bookkeeping" type="checkbox"/><label class="terms" for="bookkeeping">Bookkeeping</label>
                </div>
                <div class="checkbox">
                    <input name="taxPlanningAdvice" formControlName="taxPlanningAdvice" id="taxPlanningAdvice" type="checkbox"/><label class="terms" for="taxPlanningAdvice">Tax Planning Advice</label>
                </div>
                <textarea formControlName="message" rows="3" id="proposalMessage" placeholder="Comments" name="message" class="fc form-control mt-20"></textarea>
                <div class="mt-20">
                    <input name="newsletter" formControlName="newsletter" id="newsletter" type="checkbox" style="margin-left: 0;"/><label class="terms" for="newsletter">Subscribe to Newsletter.</label>
                </div>

                <button type="submit" [disabled]="!fgp.valid || isSending" [ngClass]="(fgp.valid && ! isSending) ? 'blue-button':'disable-blue-button'" class="mb-40 center-block mt-20 font-w font-20 text-center" type="submit" style="max-width: 550px; min-width: 200px;">SUBMIT PROPOSAL REQUEST</button>

            </div>
        </div>
        </form>

    </div>
</div>

